import React, { Component } from 'react';
import { Row, Form, InputGroup, Button, Modal, Table, Tabs, Tab} from "react-bootstrap";
import MDEditor from '@uiw/react-md-editor';
import EnsResolver from "ens-resolver";

class NavigateModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pageInput: this.props.pageInput,
      filterStartBlock: this.props.filterStartBlock,
      filterEndBlock: this.props.filterEndBlock,
      filterPoster: this.props.filterPoster,
      filterTag: this.props.filterTag,
    };
  }

  updateAndNotify = () => {
    this.setState({
      pageInput: this.props.pageInput,
      filterStartBlock: this.props.filterStartBlock,
      filterEndBlock: this.props.filterEndBlock,
      filterPoster: this.props.filterPoster,
      filterTag: this.props.filterTag,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps !== this.props) {
      this.updateAndNotify();
    }
  }

  render() {

    const { platform, userPages, newPosts, newPages,
          pageInput,
          filterStartBlock, setFilterStartBlock,
          filterEndBlock, setFilterEndBlock,
          filterPoster, setFilterPoster,
          filterTag, setFilterTag,
          updateHistory,
          isNavigateModalOpen, closeNavigateModal, openAboutModal } = this.props;

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isNavigateModalOpen} onHide={closeNavigateModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'VT323, monospace'}}>Navigate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <Tabs defaultActiveKey="navigate_to_page" id="uncontrolled-tab-example">
                <Tab eventKey="navigate_to_page" title="Go To or Mint Page">
                <p></p><MDEditor.Markdown source={platform.navigation_goto} />
                <p></p>
                <InputGroup className="mb-2">

                  <InputGroup.Text>Page</InputGroup.Text>
                  <Form.Control size="sm" id="filterPageInput" defaultValue={pageInput}
                    onChange={pageInput}
                    onBlur={updateHistory}
                  />
                </InputGroup>
                <div align="center">
                <Table>
                <Row>
                  <Button
                  variant="btn btn-block btn-success"
                  style={{fontFamily: 'VT323, monospace'}}
                  onClick={closeNavigateModal}>
                    Go to Page
                  </Button>
                  </Row>
                  </Table>
                </div>
                </Tab>

                <Tab eventKey="accout_pages" title="Your Pages">
                <p></p><MDEditor.Markdown source={platform.navigateYourPages} />
                <p></p>
                <ol>
                  {userPages.map((page, i) => (
                  <li key={`accountPages-${i}`}><Button variant="outline-secondary" href={`#/${page}`}>{page}</Button></li>
                ))}
                </ol>
                </Tab>

                <Tab eventKey="new_posts" title="New Posts">
                <p></p><MDEditor.Markdown source={platform.new_posts} />
                  <p></p>
                  <ol>
                  {newPosts.map((post, i) => (
                    <>
                    <li key={`newPost-${i}`}><Button variant="link" href={`#/tx/${post.transactionHash}`}>
                    Tx {post.transactionHash.slice(0,7)}...{post.transactionHash.slice(post.transactionHash.length-8,post.transactionHash.length)} by <EnsResolver lookup={post.returnValues.poster}/> ({post.returnValues.poster.slice(0,7)}...{post.returnValues.poster.slice(post.returnValues.poster.length-8,post.returnValues.poster.length)})
                    </Button></li>
                    <div style={{fontSize: "8pt"}}>
                     - {post.returnValues.content.slice(0,100||post.returnValues.content.length)}{(100<post.returnValues.content.length)?"...":""}
                    </div>
                    </>
                  ))}
                  </ol>
                </Tab>

                <Tab eventKey="new_pages" title="New Pages">
                  <p></p>
                  <MDEditor.Markdown source={platform.new_pages} />
                  <p></p>
                  <ol>
                  {newPages.map((page, i) => (
                    <li key={`newPage-${i}`}><Button variant="link" href={`#/${page.returnValues.page}`}>
                    {page.returnValues.page} minted by <EnsResolver lookup={page.returnValues.owner}/> ({page.returnValues.owner.slice(0,7)}...{page.returnValues.owner.slice(page.returnValues.owner.length-8,page.returnValues.owner.length)})
                    </Button></li>
                  ))}
                  </ol>
                </Tab>

                <Tab eventKey="filters" title="Filters">
                  <p></p><MDEditor.Markdown source={platform.filters_text} />
                    <p></p>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>Start Block</InputGroup.Text>
                        <Form.Control required defaultValue={filterStartBlock}
                          //onChange={e => this.setState({ filterStartBlock: e.target.value }, this.loadBlockchainData)}
                          onChange={setFilterStartBlock}
                          />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>End Block</InputGroup.Text>
                        <Form.Control required defaultValue={filterEndBlock}
                          //onChange={e => this.setState({ filterEndBlock: e.target.value }, this.loadBlockchainData)}
                          onChange={setFilterEndBlock}
                          />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>Poster</InputGroup.Text>
                        <Form.Control id="filterPosterInput" defaultValue={filterPoster}
                        //onChange={e => this.setState({ filterPoster: e.target.value }, this.loadBlockchainData)}
                        onChange={setFilterPoster}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                    <InputGroup className="mb-2">
                      <InputGroup.Text>Page</InputGroup.Text>
                      <Form.Control size="sm" id="filterPageInput" defaultValue={pageInput}
                        //onChange={setPageInput}
                        onBlur={updateHistory}
                      />
                    </InputGroup>
                    </Form.Group>

                  <Form.Group as={Row} className="mb-3">
                    <InputGroup className="mb-2">
                      <InputGroup.Text>Tag</InputGroup.Text>
                      <Form.Control id="filterTagInput" defaultValue={filterTag}
                      //onChange={e => this.setState({ filterTag: e.target.value }, this.loadBlockchainData)}
                      onChange={setFilterTag}
                      />
                    </InputGroup>
                  </Form.Group>

                </Tab>
              </Tabs>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="info btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={openAboutModal}>
                  Help / WTF Is This Website?
                </Button>
                <Button variant="secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={closeNavigateModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default NavigateModal;
