import React, { Component } from "react";
//import ReactDOM from "react-dom";

import Immutables from "./contracts/Immutables.json";

//import getWeb3 from "./getWeb3";
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";

//import { Row, Form, InputGroup, Button, Spinner, Modal, Table, Tabs, Tab} from "react-bootstrap";
import { Row, Spinner } from "react-bootstrap";

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Timeline from "./components/Timeline/Timeline";

import AdminModal from "./components/AdminModal";
import MintPageModal from "./components/MintPageModal";
import PageInfoModal from "./components/PageInfoModal";
import NavigateModal from "./components/NavigateModal";
import CreatePostModal from "./components/CreatePostModal";
import CreateTipModal from "./components/CreateTipModal";
import AboutModal from "./components/AboutModal";
import MetamaskModal from "./components/MetamaskModal";
import ChainModal from "./components/ChainModal";
import SiteNavbar from "./components/SiteNavbar";
import BottomBar from "./components/BottomBar";

import { Button } from "react-bootstrap";

import { NFTStorage } from 'nft.storage'
const apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDZjOGY3OWY0NzNDMWQzMUI3N2M5ZGFGMzk0YzU3NkY2RDcyOTBBNWYiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTYzMTUxNjk2MzM1NywibmFtZSI6IkltbXV0YWJsZXMifQ.XcdsYufy70T_qv3_1oZaagU_zD1uuHVt6rBhg2Ij5Jg'
const nftStorageClient = new NFTStorage({ token: apiKey })

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      web3: null,
      account: null,
      accounts: null,
      contract: null,
      networkId: null,
      deployedNetwork: null,
      isConnectedToADeployedToNetwork: false,

      userIsContractOwner: false,
      userIsTeammember: false,
      userIsRoyaltyRecipient: false,
      userIsPayee: false,
      teammembers: null,

      posts: [], // for the timeline

      ipfsFile: null,
      ipfsUploading: false,

      newPosts: [], // for the new posts tab
      newPages: [], // for the new pages tab
      userPages: [], // the pages owned by user

      //pageInput: this.props.match.params.page || ((!this.props.match.params.txhash) ? "Immutables" : null),
      pageInput: null,

      pageTokenId: null,
      pageOwner: null,
      pageEventHistory: [],

      sortOldToNew: true,

      filterStartBlock: 9789036, // rinkeby ; 13776036 mainnet
      filterEndBlock: "latest",
      filterPoster: null,
      filterTag: null,

      loading: true,
      currentlyMinting: false,
      currentlyPosting: false,
      adminLoading: false,
      loadingDescription: "",

      postingFee: null,
      postingFeeWei: null,
      pageFee: null,
      pageFeeWei: null,
      pageRoyaltyAddress: null,
      pageRoyaltyPercent: null,
      contractTipPercentage: null,
      posterTipPercentage: null,

      curator: null,
      curatorPercent: null,
      beneficiary: null,
      beneficiaryPercent: null,

      currentTokenId: null,

      immutablesWEB: "",
      immutablesURI: "",
      useMetadataServer: true,

      pageCanBeMinted: null,
      canPostInPage: null,

      tipFormPostAuthorInput: null,

      isAboutModalOpen: false,
      isCreatePostModalOpen: false,
      isCreateTipModalOpen: false,
      isMetamaskModalOpen: false,
      isChainModalOpen:false,
      isNavigateModalOpen:false,
      isMintPageModalOpen:false,
      isPageInfoModalOpen:false,

      styleOverride: "",

      platform: {
        symbol: "][",
        name: "][ Immutables.co",
        url: "http://co.immutables.eth.link/",

        about_immutables: "## ][ Immutables\n\nEveryone has been doing Non-Fungible Tokens (NFTs) the wrong way. Typical NFT's are fragile and rely on multiple legacy Web2.0 systems.\n\n![](01_Broke_NFT.png)\n\n**Immutables has fixed this.**  Immutables is a decentralized application (Dapp) on the Ethereum network. **It does not rely on any servers**.\n\n![](02_Woke_NFT.png)\n\nImmutables enables you to Post content into the Ethereum blockchain where it will be stored **forever on chain**.\nAuthorship, ownership, and immutability are verified and enforced cryptographically by Ethereum.\nImmutables are NFT's done right.\n\nCreate a Non-Fungible Token Page, insert Posts with content, and sell the Page on marketplaces such as [OpenSea](http://opensea.io)\n.",
        about_pages: "## Pages\n\n* You can mint a Page as a Non-Fungible Token.\n* Pages are a container for Posts.\n* Find or mint a Page using the Navigate menu.\n* Most words and phrases can be used as an identifier for a mintable Page, except:\n\t* A Page name cannot start with 0.\n* Any page with a period in the name is public.\n\t* Nobody can mint public page.\n\t* Everyone can post in a public page.\n* If you go to a page that doesn't exist you will be given the option to mint it if its mintable or post in it if its public.",
        about_posts: "## Posts\n\n* Create as many Posts in a Page as you desire.\n* Posts are cryptographically tied to your account, so only post good things.\n* Posts cannot be altered or removed by anyone.\n* Posts can contain markdown formatted text as well as image data in the form of a [Data URI](https://ezgif.com/image-to-datauri) or a link to an IPFS image.",
        about_tags: "## Tags\n\nPosts can be tagged with a single Tag.\n* For example, tag one Post in a Page as 'image' to tell OpenSea which image to use as a thumbnail.\n* Tag another post as 'description', 'attributes', or 'background_color'.\n* See [OpenSea Metadata Standards Documentation](https://docs.opensea.io/docs/metadata-standards).\n\nTags can also be used to signify that a Post is replying to another Post; tag the reply with R immediately followed by the tranaction hash of parent Post (e.g., R0x623f...18eb5562).\n\nIn the future, third-party services relying on Immutables as a backend may also devise their own tags to monitor.",
        about_on_chain: "## On Chain\n\n* All information in Immutables is stored 'on chain' in Ethereum.\n* For important or meaningful Non-Fungible Tokens, this is important.\n\t* Authorship of the content is verified.\n\t* Storage is permanent and does not rely on one or more third party servers to simultaneously be in operation.\n\t* The content cannot be modified once stored.\n* NFT's that are a true store of value should be an Immutables NFT.",
        about_metadata: "## Metadata\n\nWe serve metadata one of two ways.\n1. Using a traditional metadata server for compatibility with marketplaces.\n2. **Serverless** from our smart contract.\n\nIf we are operating with a metadata server, our metadata server will connect to the blockchain and read Posts tagged with the properties defined in [OpenSea Metadata Standards Documentation](https://docs.opensea.io/docs/metadata-standards).  Our server will then format the NFT metadata for a Page based on the tagged Posts for OpenSea.  However, in time, we hope that Immutables will redefine how NFT's are done and that OpenSea will read tagged Immutables directly from the Ethereum blockchain.\n\nTo the extent necessary, our serverless mode metadata mode will enable Immutables to go completely off grid.  It will provide name, description and simple image information directly from the smart contract.  This way Immutables has the option not relying on any external server.",
        about_contact: "## Contact Us\n\nContact us on [Twitter at @_immutables](http://www.twitter.com/_immutables).",

        page_detials_modal: "## Page Details\n\nInformation about the owner of this page, and the transaction history can be found below.  For more information about this Page, see the OpenSea and Etherscan links below.",

        metamask_modal: "Immutables exists entirely on the Ethereum blockchain.  In order to view interact with Immutables you need to get [MetaMask](https://metamask.io/).",

        navigation_goto: "## Go To Page or Find a Page to Mint\n\nFind a page. If a page doesn't exist you will be given the opportunity to mint it.",
        new_posts: "## New Immutables\n\nTen most recent Immutables Posts:",
        new_pages: "## New Pages\n\nTen most recent Immutables Pages minted:",
        filters_text: "## Filters\n\nApply filters to sift through messages by Block number (a proxy for time), the address of the poster, and a Tag which can be any string.",
        navigateYourPages: "If you own any pages, you should see them below.",

        compose_intro: "## Composing an Immutable.\n\nWrite your post in the compose window below using HTML and Markdown formatting.\n\nYou can use the toolbar to show a live preview of the finished post on the right side of the compose window.\n\nTo insert images, see the On Chain and IPFS Image Tabs to automatically process images into the proper formats.",
        compose_caution: "**This message is forever and cryptographically tied to you.  Double check for Typos.  Do not violate the law.**",
        compose_on_chain_image_before_upload: "## Add On Chain Image\n\nYou can place data directly on the blockchain by converting your image to a Data URI. Simply select an image using the form below, and it will be inserted at the end of the input field on the \"Compose Immutable Post\" tab.  **We reccomend that images be around 45kB or less.  _Bigger file sizes may cause the transaction to be rejected_.** [Here is a tool for easily manipulating image size and compression online](https://ezgif.com/optijpeg).",
        compose_on_chain_image_after_upload: "Give this a second to process.  Then, you should be able to switch back to the first tab to see a preview of the image on the right side of the compose window.",
        compose_ipfs_image: "## Add IPFS Image\n\nTo store images that are more than 45kB in file size, we reccomend using IPFS. The form below will upload your image to [nft.storage](nft.storage).\n> nft.storage is a brand new service, built specifically for storing off-chain NFT data. Data is stored decentralized on IPFS and Filecoin.\nUpload one file at a time.  Each time, an additional IPFS image tag will be added to the \"Compose\" tab input.\n\nThe images will take a few minutes to become available on IPFS, and you will not see the images in the \"Compose\" tab preview of the rendered page.",
        compose_style_css: "## Restyle your Page with CSS.\n\nClick the button below to configure the compose tab with a boilerplate style template.  After clicking the button, switch back to the compose tab, modify the style, and Post to update your page's appearance.",

        compose_tip_intro: "## Tipping a Post.\n\nEnter a tip amount in Eth below.\n\nSee how your tip is distributed at the bottom of this dialog.",

        mintImmutableModalText: "Minting a page into a NFT gives you a private area to make posts that is saleable on OpenSea and other NFT marketplaces.\n\n**Note:** Pages with names that include spaces may not be linked properly from OpenSea.  Consider using an underscore (_) or dash (-) instead of a space if you want to make sure that the OpenSea listing links directly to your Page.",

        adminServerConfigText: "ContractOwner can configure whether or not the backend server is via a tokenURI URL or whether it is served directly from the contract.",
        adminUserFeeText: "ContractOwner can configure the fee for posting and for creating a page.  Fees in Ether.  [Currency unit converter](https://etherscan.io/unitconverter).",
        adminUserValueFeeText: "ContractOwner can configure the fee splits for tipping.  Percentages are expressed in basis points.",
        adminPosterScreening: "Administrator can require posters need to be pre-screened.",
        adminCuratorText: "ContractOwner can configure a curator and a percentage cut of the fees withdrawn from the contract.",
        adminBeneficaryText: "ContractOwner can configure a beneficiary and a percentage cut of the fees withdrawn from the contract.",
        adminWithdrawText: "Payees can withdraw funds from the contract.",
        adminRoyaltyInfo: "RoyaltyRecipient can modify royalty address and percent (0 to 1000 (0% to 10.00%)).",
        adminRoyaltyContractAddress: "This is the address of the Royalty Manager contract associated with this Page.",
        adminRoyaltyContractBalance: "The Royalty Manager contract for this project currently contains the following amount.",
        adminRoyaltyContractAdministerOnEtherscan: "Administer and Release funds from the Royalty Manager Contract for this Page on Etherscan.",

        opensea_collection_link:"https://testnets.opensea.io/collection/immutables",
        opensea_asset_base_link: `https://testnets.opensea.io/assets/`,
        etherscan_link: `https://rinkeby.etherscan.io/address/`,
        etherscan_token_link: `https://rinkeby.etherscan.io/token/`,
        etherscan_tx_link:"https://rinkeby.etherscan.io/tx/",
        etherscan_block_link:"https://rinkeby.etherscan.io/block/",
        etherscan_token_query_after_contract: "?a=",

        cause: "",
      }
    };

    // Bind the this context to the handler function
    this.accountClickHandler = this.accountClickHandler.bind(this);
    this.tagClickHandler = this.tagClickHandler.bind(this);
    this.seeRepliesClickHandler = this.seeRepliesClickHandler.bind(this);
    this.pageClickHandler = this.pageClickHandler.bind(this);
    this.postReplyHandler = this.postReplyHandler.bind(this);
    this.transactionClickHandler = this.transactionClickHandler.bind(this);
    this.withdraw = this.withdraw.bind(this);

    this.adminUpdateWebsiteUrl = this.adminUpdateWebsiteUrl.bind(this);
    this.adminUpdateMetadataServerUrl = this.adminUpdateMetadataServerUrl.bind(this);
    this.adminSetUseMetadataServer = this.adminSetUseMetadataServer.bind(this);
    this.adminUpdatePostingFee = this.adminUpdatePostingFee.bind(this);
    this.adminUpdatePageFee = this.adminUpdatePageFee.bind(this);
    this.contractOwnerUpdateBeneficiaryAddressAndPercent = this.contractOwnerUpdateBeneficiaryAddressAndPercent.bind(this);

    //this.pageEventHistory = this.pageEventHistory.bind(this);

    this.openAdminModal = this.openAdminModal.bind(this);
    this.closeAdminModal = this.closeAdminModal.bind(this);
    this.openAboutModal = this.openAboutModal.bind(this);
    this.closeAboutModal = this.closeAboutModal.bind(this);
    this.openCreatePostModal = this.openCreatePostModal.bind(this);
    this.closeCreatePostModal = this.closeCreatePostModal.bind(this);
    this.openCreateTipModal = this.openCreateTipModal.bind(this);
    this.closeCreateTipModal = this.closeCreateTipModal.bind(this);
    this.openMintPageModal = this.openMintPageModal.bind(this);
    this.closeMintPageModal = this.closeMintPageModal.bind(this);
    this.openPageInfoModal = this.openPageInfoModal.bind(this);
    this.closePageInfoModal = this.closePageInfoModal.bind(this);
    this.openNavigateModal = this.openNavigateModal.bind(this);
    this.closeNavigateModal = this.closeNavigateModal.bind(this);
    this.openMetamaskModal = this.openMetamaskModal.bind(this);
    this.closeMetamaskModal = this.closeMetamaskModal.bind(this);
    this.openChainModal = this.openChainModal.bind(this);
    this.closeChainModal = this.closeChainModal.bind(this);

    this.updateHistory = this.updateHistory.bind(this);
    this.setFilterStartBlock = this.setFilterStartBlock.bind(this);
    this.setFilterEndBlock = this.setFilterEndBlock.bind(this);
    this.setFilterPoster = this.setFilterPoster.bind(this);
    this.setFilterTag = this.setFilterTag.bind(this);
    this.setComposeFormContentInput = this.setComposeFormContentInput.bind(this);
    this.setComposeFormTagContentInput = this.setComposeFormTagContentInput.bind(this);
    this.setTipFormPostAuthorInput = this.setTipFormPostAuthorInput.bind(this);

    this.anyonePostPageTagContent = this.anyonePostPageTagContent.bind(this);
    this.anyonePayPagePostPosterValue = this.anyonePayPagePostPosterValue.bind(this);
    this.mint = this.mint.bind(this);

    this.setPageInput = this.setPageInput.bind(this);

    this.contractOwnerRoyaltyRecipientUpdateRoyaltyInfo = this.contractOwnerRoyaltyRecipientUpdateRoyaltyInfo.bind(this);

    this.configurePostWithStyle = this.configurePostWithStyle.bind(this);

    this.teamToggleUserScreeningEnabled = this.teamToggleUserScreeningEnabled.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.toggleSortOldToNew = this.toggleSortOldToNew.bind(this);

    this.getAbbreviatedHash = this.getAbbreviatedHash.bind(this);
  }

  componentDidMountOld = async () => {
    await this.loadWeb3() // Try to get web3 or show MetaMask modal
    if(window.web3) {
      this.setState({loadingDescription: "Getting Contract State..."})
      await this.setupAccountsAndContract() // Try to setup contract.

      await this.loadBlockchainData()

      // Lets watch for account changes
      window.ethereum.on('accountsChanged', accounts => {
        // Time to reload your interface with accounts[0]!
        this.setState({accounts})
        this.setState({account: accounts[0]})
        window.location.reload();
      })

      window.ethereum.on('chainChanged', function (networkId) {
        // Time to reload your interface with the new networkId
        window.location.reload();
      })
    }
  }

  loadWeb3Old = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      //window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
      this.setState({loading: false});
      this.openMetamaskModal();
    }
  }

  componentDidMount = async () => {
    this.setState({loadingDescription: "please connect web3..."})
    await this.loadWeb3() // Try to get web3 or show MetaMask modal
  }

  loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else if(!this.state.triedWeb3Modal) {
      this.setState({triedWeb3Modal:true})
        // Start Web3Modal
        const providerOptions = {
          walletconnect: {
            display: {
              name: "Mobile"
            },
            package: WalletConnectProvider,
            options: {
              infuraId: "9a3ae3b9ca7e40c0b12c3c08159d48ed", // required
              qrcodeModalOptions: {
                mobileLinks: [
                  "rainbow",
                  "metamask",
                  "argent",
                  "trust",
                  "imtoken",
                  "pillar",
                ],
              }
            }
          }
        };

       const web3Modal = new Web3Modal({
         //network: "mainnet", // optional
         cacheProvider: false, // optional
         providerOptions: providerOptions, // required
       });

       const provider = await web3Modal.connect();
       window.ethereum = provider;
       window.web3 = new Web3(provider);
       // End web3Modal
    } else {
      this.openMetamaskModal();

      //window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
      console.log("loadWeb3 - no window.ethereum, no window.web3, else: this.openMetamaskModal()")
      this.setState({loading: false});
    }

    if(window.web3) {
      this.setState({loadingDescription: "getting contract state..."})
      await this.setupAccountsAndContract(); // Try to setup contract.

      // Lets watch for account changes
      window.ethereum.on('accountsChanged', accounts => {
        // Time to reload your interface with accounts[0]!
        this.setState({accounts, account: accounts[0]})
        window.location.reload();
      })

      window.ethereum.on('chainChanged', function (networkId) {
        // Time to reload your interface with the new networkId
        window.location.reload();
      })

      // Subscribe to provider connection
      window.ethereum.on("connect", (info: { chainId: number }) => {
        console.log(info);
      });

      // Subscribe to provider disconnection
      window.ethereum.on("disconnect", (error: { code: number; message: string }) => {
        console.log(error);
      });
    } else {
    }
  }

  setupAccountsAndContract = async () => {
    // Load account
    const web3 = window.web3

    const accounts = await web3.eth.getAccounts();
    this.setState({ account: accounts[0] });
    // Network ID
    const networkId = await web3.eth.net.getId();
    const deployedNetwork = Immutables.networks[networkId];
    if(deployedNetwork) {
      this.setState({ isConnectedToADeployedToNetwork: true });
      const instance = new web3.eth.Contract(
        Immutables.abi,
        deployedNetwork && deployedNetwork.address,
      );

      let userIsContractOwner = false;
      let owner = await instance.methods.owner().call()
      if(owner === accounts[0]) {
        userIsContractOwner = true;
      }
      let userIsTeammember = await instance.methods.isTeammember(accounts[0]).call();

      if(networkId === 1) {
        this.setState({filterStartBlock: 13776036})
        this.setState(prevState => ({
            platform: {                   // object that we want to update
                ...prevState.platform,    // keep all other key-value pairs
                opensea_collection_link:"https://opensea.io/collection/immutables",
                opensea_asset_base_link:"https://opensea.io/assets/",
                etherscan_link:"https://etherscan.io/address/",
                etherscan_token_link:"https://etherscan.io/token/",
                etherscan_tx_link:"https://etherscan.io/tx/",
                etherscan_block_link:"https://etherscan.io/block/",
            }
        }))
      }

      //let userIsContractOwner = await instance.methods.postingFee().call()
      let postingFeeWei = await instance.methods.postingFee().call()
      let postingFee = await web3.utils.fromWei(postingFeeWei, 'ether')
      let pageFeeWei = await instance.methods.pageFee().call()
      let pageFee = await web3.utils.fromWei(pageFeeWei, 'ether')
      let contractTipPercentage = await instance.methods.contractTipPercentage().call()
      let posterTipPercentage = await instance.methods.posterTipPercentage().call()

      let curator = await instance.methods.curator().call()
      let curatorPercent = await instance.methods.curatorPercent().call()
      let beneficiary = await instance.methods.beneficiary().call()
      let beneficiaryPercent = await instance.methods.beneficiaryPercent().call()

      let userIsPayee = (userIsContractOwner || curator === accounts[0] || beneficiary === accounts[0]);

      this.setState({ networkId, deployedNetwork,
        postingFee, postingFeeWei, pageFee, pageFeeWei,
        contractTipPercentage, posterTipPercentage,
        userIsContractOwner, userIsTeammember, userIsPayee,
        curator, curatorPercent,
        beneficiary, beneficiaryPercent,

        contract: instance
      })

      // Monitor for events
      this.state.contract.events.AddressPostedPageTagContent({
        //filter: {poster: this.state.filterPoster, pageHash: (this.state.pageInput) ? window.web3.utils.keccak256(this.state.pageInput) : null, tagHash: (this.state.filterTag) ? window.web3.utils.keccak256(this.state.filterTag) : null},
        filter: {},
        fromBlock: this.state.filterStartBlock,
        toBlock: this.state.filterEndBlock
      }, (error, data) => {
        if (error) {
          console.log("Error: " + error);
        } else {
          //console.log("Log data: " + data);
          this.loadBlockchainData();
        }
      });

      this.state.contract.events.AddressReservedPage({
        filter: {},
        fromBlock: this.state.filterStartBlock,
        toBlock: this.state.filterEndBlock
      }, (error, data) => {
        if (error) {
          console.log("Error: " + error);
        } else {
          //console.log("Log data: " + data);
          this.loadBlockchainData();
        }
      });

    } else {
       //window.alert('Contract not deployed to detected network.')
       this.setState({loading:false});
       this.openChainModal();
     }
  }

  loadBlockchainData = async () => {
    this.setState({loadingDescription: "Getting Page Information..."})
    await this.setupCurrentPage()
    this.setState({loadingDescription: "Getting Posts for Page..."})
    await this.getPosts()
    this.setState({loadingDescription: "Getting Admin Settings..."})
    await this.getAdminSettings()
    this.setState({loadingDescription: "Getting Recent Activity..."})
    this.getNewPages(10)
    this.getAllNewPosts(10)
    if(this.state.isConnectedToADeployedToNetwork) {
      this.setState({loadingDescription: "Getting Your Pages..."})
      this.getUserPages()
    }
    this.setState({loading: false, loadingDescription: ""})
    //this.forceUpdate();
  }

  setupCurrentPage = async () => {
    const web3 = window.web3
    if(web3) {
      if(this.state.deployedNetwork) {
        const currentTokenId = await this.state.contract.methods.currentTokenId().call()

        if(this.props.match.params.page) {
          this.setState({pageInput: this.props.match.params.page})
        }

        if(!this.props.match.params.txhash && this.state.pageInput === null) {
          if(
             (this.state.filterPoster !== null) ||
             (this.state.filterTag !== null)
           ) {
             console.log("setupCurrentPage - not transaction hash - page input is null - filters set - do nothing.")
           } else {
             console.log("setupCurrentPage - not transaction hash - page input is null - no filters set - try to set to Immutables default page.")

             this.setState({pageInput: "Immutables"});
             //this.pageClickHandler("Immutables");
          }
        }

        if(!this.props.match.params.txhash) {
          console.log("========== setupCurrentPage ==========")

          console.log("Page Name: ", this.state.pageInput)

          let pageCanBeMinted = false;
          let isValidPageName = false;
          if(this.state.pageInput) {
            pageCanBeMinted = await this.state.contract.methods.isValidAndAvailablePageName(this.state.pageInput).call()
            console.log("isValidAndAvailablePageName: ", pageCanBeMinted)
            isValidPageName = await this.state.contract.methods.isValidPageName(this.state.pageInput).call();
            console.log("Is Valid Name: ", isValidPageName)
          }

          let pageTokenId = 0
          try {
            pageTokenId = await this.state.contract.methods.pageToTokenId(this.state.pageInput).call()
            this.pageEventHistory(pageTokenId)
          } catch(error) { console.error(error) }
          console.log("pageTokenId: ", pageTokenId)

          let pageOwner = 0;
          try {
            pageOwner = await this.state.contract.methods.ownerOf(pageTokenId).call()
          } catch(error) { console.error(error) }
          console.log("pageOwner: ", pageOwner)

          let userIsPageOwner = false;
          if(pageOwner === this.state.account) {
            userIsPageOwner = true;
          }

          // TODO: update with new contract deployment
          //let pageRoyaltyAddress = "TEST"
          //let pageRoyaltyPercent = 100
          let pageRoyaltyAddress = await this.state.contract.methods.tokenIdToRoyaltyAddress(pageTokenId).call()
          let pageRoyaltyPercent = await this.state.contract.methods.tokenIdToSecondaryRoyaltyPercent(pageTokenId).call()
          let pageRoyaltyContractBalanceWei = await window.web3.eth.getBalance(pageRoyaltyAddress)
          const pageRoyaltyContractBalance = await window.web3.utils.fromWei(pageRoyaltyContractBalanceWei, "ether")

          // Should be false when user does not have permission to post in page.
          this.setState({
            currentTokenId,
            pageCanBeMinted,
            pageTokenId,
            pageOwner,
            pageRoyaltyAddress,
            pageRoyaltyPercent,
            userIsPageOwner,
            pageRoyaltyContractBalanceWei,
            pageRoyaltyContractBalance,
          })

          try {
            if(!isValidPageName) {
              this.setState({ canPostInPage: true })
              console.log("This is a public page, anyone can post here and it cannot be purchased.")
            } else if(isValidPageName && pageOwner === 0) {
              this.setState({canPostInPage: false, pageCanBeMinted: true})
              console.log("This is a valid page that is not owned.  It could be yours.")
              this.openMintPageModal();
            } else if(pageOwner === this.state.account) {
              this.setState({ canPostInPage: true })
              console.log("You own this page and can post here.")
            } else if(this.state.userIsTeammember === true) {
              //this.setState({ canPostInPage: true })
              //console.log("You are have administrative access and can post here to hide posts that violate terms of service.")
            } else {
              this.setState({ canPostInPage: false })
              console.log("Not authorized to post here.")
            }
          } catch (error) {
            console.log(error)
          }
        } else {
            console.log("You are viewing a transaction hash directly.")
            this.setState({canPostInPage: false})
            this.setState({pageCanBeMinted: false})
        }
      }
    }
  }

  getPosts = async () => {
    const web3 = window.web3;
    let posts = []
    if(web3) {
      if(this.state.deployedNetwork) {
        if(this.props.match.params.txhash) {
          posts = await this.getPostsFromTransactionHash(this.props.match.params.txhash);
        } else {
          posts = await this.getPostsForPageTag(this.props.match.params.page, this.state.filterTag);
        }
      } // this.state.deployedNetwork
    } //web3
    this.setState({posts});
  } //getPostsForCurrentPage

  getPostsFromTransactionHash = async (txhash) => {
      const web3 = window.web3
      let posts = [];
      // if we have a transaction hash
      // -- lets just grab that
      // -- console.log(this.props.match.params.txhash)
      this.setState({pageInput: ""})
      console.log(this.props.match.params.txhash);
      await web3.eth.getTransaction(this.props.match.params.txhash, function(err, tx){
          console.log(tx);
          let tx_data = tx.input;
          let input_data = '0x' + tx_data.slice(10);  // get only data without function selector

          let params = web3.eth.abi.decodeParameters(['string', 'string', 'string'], input_data);
          console.log("Params: ", params);

          let post = {
            transactionHash: tx.hash,
            blockNumber: tx.blockNumber,
            returnValues: {
              content: params[2],
              page: params[0],
              poster: tx.from,
            }
          };
          console.log("Content: ", params[2]);
          console.log(post)
          posts.push(post);
      }).catch(function(error) { console.log(error) });
      if(!this.state.sortOldToNew) {
        posts = posts.sort((a,b) => b.blockNumber - a.blockNumber )
      }
      //this.setState({posts: posts});
      return posts;
  }

  getPostsForPageTag = async (page, tag) => {
      // if we have a page
      // -- load posts from the page
      // -- console.log(this.props.match.params.page)
      console.log("Tag value: ", tag)
      if(tag===null || tag===undefined) { tag = "" }
      console.log("Tag value: ", tag)

      console.log(this.props.match.params.page)
      if(this.props.match.params.page){
        this.setState({pageInput: this.props.match.params.page})
        //this.forceUpdate();
      }

      let startBlock = this.state.filterStartBlock; //this.state.fromBlock;
      let currentBlock = (this.state.filterEndBlock === 'latest') ? await window.web3.eth.getBlockNumber() : this.state.filterEndBlock;
      let incrementByBlocks = 3000;

      let posts = [];

      let currentNetworkId = this.state.networkId;

      // Load posts for page in localStorage.
      this.setState({loadingDescription: `loading local storage ...`});

      let stillLoading = true;
      let j = 0;
      while(stillLoading){
        try {
          let storageName =`${currentNetworkId}-${page}-${j}`;
          console.log("storageName: ", storageName)
          
          let tokenData = window.localStorage.getItem(storageName);
          let token = JSON.parse(tokenData);
          startBlock = token.blockNumber + 1;
          if(tag !== "") {
            if(tag === token.returnValues.tag) {
              posts.push(token);
            }
          } else {
            posts.push(token)
          }
          j++;
        } catch(error) { 
          console.log(error)
          stillLoading = false;
        };
      }

      // Find the last block searched for this page
      try {
        let storageName = `${currentNetworkId}-${page}-last_block_searched`;
        let tokenData = window.localStorage.getItem(storageName);
        let lastSearchedBlock = JSON.parse(tokenData);
        startBlock = lastSearchedBlock.blockNumber + 1;
      } catch(error) { console.log(error) };
      
      // Load any new posts from subsequent blocks
      let i = 0;
      while((startBlock + incrementByBlocks*i) < (currentBlock)) {
        try {

          let thisStartBlock = (startBlock + incrementByBlocks*i)
          let proposedEndBlock = (startBlock + incrementByBlocks*(i+1))
          let endBlock = (proposedEndBlock < currentBlock) ? (proposedEndBlock) : (currentBlock)

          console.log("startBlock endBlock: ", thisStartBlock, endBlock)

          this.setState({loadingDescription: `Loading Content from Chain ${parseFloat((thisStartBlock-startBlock)/(currentBlock-startBlock)*100).toFixed(0)}% ...`});

          await this.state.contract.getPastEvents('AddressPostedPageTagContent', {
              filter: {poster: this.state.filterPoster,
                     pageHash: (this.state.pageInput) ? window.web3.utils.keccak256(this.state.pageInput) : null,
                     tagHash: (tag) ? window.web3.utils.keccak256(tag) : (this.state.filterTag) ? window.web3.utils.keccak256(this.state.filterTag) : null},
              fromBlock: thisStartBlock, //this.state.filterStartBlock,
              toBlock: endBlock //this.state.filterEndBlock
          })
          .then(function(events){
              for(var i=0;i<events.length;i++){
                  console.log("getPostsForPageTag: ", events[i]);
                  let storageName = `${currentNetworkId}-${events[i].returnValues.page}-${j++}`;
                  window.localStorage.setItem(storageName, JSON.stringify(events[i]));
                  if(tag !== "") {
                    if(tag === events[i].returnValues.tag) {
                      posts.push(events[i]);
                    }
                  } else {
                    posts.push(events[i])
                  }
              }
              //this.setState({posts});
          }).catch(function(error) { console.log(error) });
        } catch (error) {
          console.error(error);
        }

        i++;
      }

      // Save the last block searched for this page
      let storageName = `${currentNetworkId}-${page}-last_block_searched`;
      let lastSearchedBlock = JSON.stringify({blockNumber: currentBlock});
      window.localStorage.setItem(storageName, lastSearchedBlock);

      // Sort the posts
      if(!this.state.sortOldToNew) {
        posts = posts.sort((a,b) => b.blockNumber - a.blockNumber )
      }

      // Return the posts
      //this.setState({posts: posts});
      return posts;
  }

  getAdminSettings = async () => {
    const web3 = window.web3
    if(web3) {
      if(this.state.deployedNetwork) {
        if(this.state.userIsContractOwner) {

          console.log("========== Getting Admin Settings ==========")
          console.log(this.state.contract);
          const maxTotalSupply = await this.state.contract.methods.currentTokenId().call()
          const immutablesWEB = await this.state.contract.methods.immutablesWEB().call()
          const immutablesURI = await this.state.contract.methods.immutablesURI().call()
          const useMetadataServer = await this.state.contract.methods.useMetadataServer().call()
          const userScreeningEnabled = await this.state.contract.methods.userScreeningEnabled().call();
          const beneficiary = await this.state.contract.methods.beneficiary().call()
          const beneficiaryPercent = await this.state.contract.methods.beneficiaryPercent().call()
          const contractBalanceWei = await web3.eth.getBalance(this.state.contract.options.address)
          const contractBalance = await web3.utils.fromWei(contractBalanceWei, "ether")

          console.log(maxTotalSupply, immutablesWEB, immutablesURI, useMetadataServer, userScreeningEnabled, beneficiary, beneficiaryPercent, contractBalance);

          this.setState({ maxTotalSupply, immutablesWEB, immutablesURI, useMetadataServer, userScreeningEnabled, beneficiary, beneficiaryPercent, contractBalance });
        }
      }
    }
  }

  getTeammembers = async () => {
      this.setState({adminLoading:true})

      let teammembers = {};
      await this.state.contract.getPastEvents('AdminModifiedTeammembers', {
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          teammembers[events[i].returnValues.user] = events[i].returnValues.isTeammember;
        }
      }).catch(function(error) { console.log(error) });
      console.log("getTeammembers: ", teammembers)
      this.setState({adminLoading:false, teammembers});
  }

  teamToggleUserScreeningEnabled = () => {
  this.setState({ adminLoading: true })
  this.state.contract.methods.teamToggleUserScreeningEnabled().send({from: this.state.account})
  .once('receipt', async (receipt) => {
    this.setState({ adminLoading: false })
    console.log(receipt);
    window.location.reload();
  })
  }

  teamAddAllowedUser = (newUser) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.teamAddAllowedUser(newUser).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  teamRemoveAllowedUser = (delUser) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.teamRemoveAllowedUser(delUser).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  getAllowedUsers = async () => {
      this.setState({adminLoading:true})

      let allowedUsers = {};
      await this.state.contract.getPastEvents('AdminModifiedAllowedUsers', {
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          allowedUsers[events[i].returnValues.user] = events[i].returnValues.isAllowedUser;
        }
      }).catch(function(error) { console.log(error) });
      console.log("getAllowedUsers: ", allowedUsers)
      this.setState({adminLoading: false, allowedUsers});
  }

  // gets all of the new posts for the navigation "new posts" tab.
  getAllNewPosts = async (number) => {
      let newPosts = [];

      try {
        await this.state.contract.getPastEvents('AddressPostedPageTagContent', {
            filter: {},
            fromBlock: this.state.filterStartBlock,
            toBlock: this.state.filterEndBlock
        })
        .then(function(events){
          let start;
          if(number < events.length) {
            start = events.length-number;
          } else {
            start = 0;
          }
          for(var i=start;i<events.length;i++){
              console.log(events[i]);
              newPosts.push(events[i]);
          }
            //this.setState({posts});
        }).catch(function(error) { console.log(error) });
      } catch (error) {
        console.error(error);
      }

      if(!this.state.sortOldToNew) {
        newPosts = newPosts.sort((a,b) => b.blockNumber - a.blockNumber )
      }
      this.setState({newPosts: newPosts});
      return newPosts;
  }

  // gets all of the new pages for the navigation "new pages" tab.
  getNewPages = async (number) => {
      let pages = [];
      // owner, page, tokenid
      try {
        await this.state.contract.getPastEvents('AddressReservedPage', {
            filter: {},
            fromBlock: this.state.filterStartBlock,
            toBlock: this.state.filterEndBlock
        })
        .then(function(events){
          let start;
          if(number < events.length) {
            start = events.length-number;
          } else {
            start = 0;
          }
          for(var i=start;i<events.length;i++){
              console.log(events[i]);
              pages.push(events[i]);
          }
            //this.setState({posts});
        }).catch(function(error) { console.log(error) });
      } catch (error) {
        console.error(error);
      }

      if(!this.state.sortOldToNew) {
        pages = pages.sort((a,b) => b.blockNumber - a.blockNumber )
      }
      this.setState({newPages: pages});
      return pages;
  }

  getUserPages = async () => {
      let balance = await this.state.contract.methods.balanceOf(this.state.account).call();
      console.log("user has this many pages: ", balance);
      let tokenIdList = [];
      let pages = [];
      console.log(this.state.currentTokenId)
      try {
        for (let i = 1; i <= this.state.currentTokenId; i++) {
            let ownerOfToken = await this.state.contract.methods.ownerOf(i).call();
            if(ownerOfToken === this.state.account) {
              tokenIdList.push(i);
              console.log(ownerOfToken, i)
            }
        }
        for(let i = 0; i < tokenIdList.length; i++){
            pages.push(await this.state.contract.methods.tokenIdToPage(tokenIdList[i]).call());
        }
    } catch (error) { console.error(error) }
    this.setState({userPages: pages})
      //console.log(this.state.contract)
  }

  pageEventHistory = async (pageId) => {
    let pageEvents = [];
    if(this.state.contract) {
      this.state.contract.getPastEvents({}, {
          filter: {tokenId: pageId},
          fromBlock: 0,
          toBlock: 'latest'
      })
      .then(function(events){
        //console.log(events)
          for(var i=0;i<events.length;i++){
              if(events[i].returnValues.tokenId === pageId || events[i].returnValues.pageId === pageId) {
                console.log(events[i]);
                //console.log(events[i].transactionHash, events[i].event, events[i].returnValues);
                pageEvents.push(events[i]);
              }
          }
      }).catch(function(error) { console.log(error) });
    }
    this.setState({pageEventHistory: pageEvents})
    return pageEvents;
  }



  /// ===== Blockchain Data Functions End  ====================

  /// ===== Modal View Hide Functions Start ====================

  //openAdminModal = () => this.setState({ isAdminModalOpen: true });
  //closeAdminModal = () => this.setState({ isAdminModalOpen: false });
  openAdminModal() { this.setState({ isAdminModalOpen: true}); }
  closeAdminModal() { this.setState({ isAdminModalOpen: false}); }

  openAboutModal() { this.setState({ isAboutModalOpen: true}); }
  closeAboutModal() { this.setState({ isAboutModalOpen: false}); }

  openCreatePostModal() {this.setState({ isCreatePostModalOpen: true}); }
  closeCreatePostModal() { this.setState({ isCreatePostModalOpen: false}); }

  openCreateTipModal(post) {
    this.setState({
      isCreateTipModalOpen: true,
      pageInput: post.returnValues.page,
      pageOwner: post.returnValues.poster
    })
  };

  closeCreateTipModal() { this.setState({ isCreateTipModalOpen: false}); }

  openMintPageModal() { this.setState({ isMintPageModalOpen: true }); }
  closeMintPageModal() { this.setState({ isMintPageModalOpen: false}); }

  openPageInfoModal() { this.setState({ isPageInfoModalOpen: true}); }
  closePageInfoModal() { this.setState({ isPageInfoModalOpen: false}); }

  openNavigateModal() { this.setState({ isNavigateModalOpen: true }); }
  closeNavigateModal() { this.setState({ isNavigateModalOpen: false}); }

  openMetamaskModal() { this.setState({ isMetamaskModalOpen: true}); }
  closeMetamaskModal() { this.setState({ isMetamaskModalOpen: false}); }

  openChainModal() { this.setState({ isChainModalOpen: true}); }
  closeChainModal() { this.setState({ isChainModalOpen: false}); }
  /// ===== Modal View Hide Functions End ====================

  /// ===== Data URI Functions Start ====================
  captureFileForDataURI(files) {
    let image_tag = "<img src=\"" + files.base64 + "\" width=\"100%\">"
    let newComposeFormContentInput  = ""
    if(this.state.composeFormContentInput !== undefined) {
      newComposeFormContentInput = this.state.composeFormContentInput + image_tag
    } else {
      newComposeFormContentInput = image_tag
    }
    this.setState({ composeFormContentInput: newComposeFormContentInput })
  }

  /// ===== Data URI Functions End ====================

  /// ===== IPFS Functions Start ====================
  // IPFS File Upload
  // From Decentragram Tutorial
  // 1:40:08
  // https://www.youtube.com/watch?v=8rhueOcTu8k

  captureFileForIPFS = async (event) => {
    event.preventDefault();
    console.log(event.target.files[0])
    const file = event.target.files[0]
    this.setState({ipfsFile: file})
  }

  uploadImageToIPFS = async (event) => {
    console.log("Submitting file to NFT.Storage...")
    this.setState({ipfsUploading: true})

    const metadata = await nftStorageClient.store({
      name: this.state.pageInput,
      description: this.state.ipfsFile.name,
      image: this.state.ipfsFile
    })

    this.setState({ipfsUploading: false})
    console.log(metadata.data)

    let image_ipfs_url = metadata.data.image.href
    let image_tag = "\n![](" + image_ipfs_url + ")\n"
    let newComposeFormContentInput  = ""
    if(this.state.composeFormContentInput !== undefined) {
      newComposeFormContentInput = this.state.composeFormContentInput + image_tag
    } else {
      newComposeFormContentInput = image_tag
    }
    this.setState({ composeFormContentInput: newComposeFormContentInput })
  }

  /// ===== IPFS Functions End  ====================
  setStyleOverride = (style) => {
    this.setState({styleOverride: style});
  }

  configurePostWithStyle = async (event) => {
    console.log("Inserting Style Example into Post...")

    let exampleStyle = `
    /* You can style your Immutables Page by overriding the existing CSS in a Post with the Tag "style" */
    /* You can prototype these overrides using the Inspect console in Chrome */
    /* Modify the example below and remove the comments before Posting to save gas */

    body {
      color: rgba(255,255,255,1); /* Page text color */
      background-color: rgba(50,50,50,1); /* Page background color */
    }

    /* Posts are placed in a .card element */
    .card {
        background-color: rgba(0,0,0,1); /* The background color for the Post card */
        border: 2px solid rgba(255,255,255,.5); /* The border for the Post card */
        border-radius: 0.25rem; /* The border radius for the Post card */
    }

    .wmde-markdown a {
        color: #0366d6; /* Link color within Posts */
    }

    /* Posts that contain computer code */
    .wmde-markdown pre code {
      color: rgba(0,255,0,1); /* Code text color */
      background-color: rgba(0,0,0,1); /* Code background color */
    }

    /* Post details table */
    .table>:not(caption)>*>* {
        color: rgba(255,255,255,1);
    }

    /* Immutables Modal UI Elements */
    .modal-content {
        background-color: rgba(100,100,100,1);
        border: 1px solid rgba(0,0,0,.125);
        border-radius: 0.25rem;
    }

    /* Modal Tabs */
    .nav-link.active {
        color: #495057; /* Active tab text color */
        background-color: rgba(255,255,255,1); /* Active tab background color */
    }
    .nav-link {
      color: rgba(255,255,255,0.5); /* Inactive tab text color */
    }

    /* Immutables Form Input Fields */
    .form-control {
      background-color: rgba(200,200,200,1);
    }

    /* Immutables Navbar UI Elements */
    .bg-light {
        --bs-bg-opacity: 1;
        background-color: rgba(0,0,0,.5)!important;
    }
    `

    let newComposeFormContentInput  = ""
    if(this.state.composeFormContentInput !== undefined) {
      newComposeFormContentInput = this.state.composeFormContentInput + exampleStyle
    } else {
      newComposeFormContentInput = exampleStyle
    }

    this.setState({
      composeFormTagContentInput: "style",
      composeFormContentInput: newComposeFormContentInput
    })
  }

  /// ===== Anyone Functions Start ====================

  updateHistory = async (e) => {
    //this.state.history.push(`/${e.target.value}`);
    //this.setState( {history: this.state.history.push(`/${e.target.value}`)})
    this.props.history.push(`/${e.target.value}`);
    this.setState({pageInput: e.target.value});
    await this.loadBlockchainData();
    //window.location.reload();
  }

  setFilterStartBlock = async (e) => {
    this.setState( {filterStartBlock: e.target.value});
    await this.loadBlockchainData();
  }

  setFilterEndBlock = async (e) => {
    this.setState( {filterEndBlock: e.target.value});
    await this.loadBlockchainData();
  }

  setFilterPoster = async (e) => {
    this.setState( {filterPoster: e.target.value, posts:[]});
    await this.loadBlockchainData();
  }

  setFilterTag = async (e) => {
    this.setState( {filterTag: e.target.value});
    await this.loadBlockchainData();
  }

  setPageInput  = async (e) => {
    this.setState( {pageInput: e.target.value, posts:[]});
    this.loadBlockchainData();
    //this.setState( {fiterPoster: ""});
    //await this.loadBlockchainData();
    //this.setState({filterPoster: this.state.filterPosterInput});
    //await this.loadBlockchainData();
  }

  setComposeFormTagContentInput(newFormTagContent) {
    this.setState({composeFormTagContentInput: newFormTagContent});
  }

  setTipFormPostAuthorInput(newTipFormPostAuthorInput) {
    this.setState({tipFormPostAuthorInput: newTipFormPostAuthorInput});
  }

  setComposeFormContentInput(newFormContent) {
    this.setState( {composeFormContentInput: newFormContent});
  }

  anyonePostPageTagContent = (page, tag, content) => {
    this.setState({ currentlyPosting: true })
    this.state.contract.methods.anyonePostPageTagContent(page, tag, content).send({from: this.state.account, value: this.state.postingFeeWei})
    .once('receipt', async (receipt) => {
      this.setState({ currentlyPosting: false })
      console.log(receipt);
      this.setState({ composeFormTagContentInput: null, composeFormContentInput: null, isCreatePostModalOpen: false})
      window.location.reload();
    })
  };

  anyonePayPagePostPosterValue = (page, postTransactionHash, postAuthor, tipInEth) => {
    this.setState({ currentlyPosting: true })
    const tipInWei = window.web3.utils.toWei(tipInEth);
    this.state.contract.methods.anyonePayPagePostPosterValue(page, postTransactionHash, postAuthor).send({from: this.state.account, value: tipInWei})
    .once('receipt', async (receipt) => {
      this.setState({ currentlyPosting: false })
      console.log(receipt);
      this.setState({ isCreateTipModalOpen: false})
      window.location.reload();
    })
  };

  mint = () => {
    this.setState({ currentlyMinting: true })
    this.state.contract.methods.anyoneReserveUnclaimedPage(this.state.pageInput).send({from: this.state.account, value: this.state.pageFeeWei})
    .once('receipt', async (receipt) => {
      const currentTokenId = await this.state.contract.methods.currentTokenId().call()
      this.setState({ currentTokenId, currentlyMinting: false })
      window.location.reload();
      this.closeMintPageModal();
      alert(`You now own ][ Immutables # ${ currentTokenId } - Page: ${this.state.pageInput}!`)
    })
  }
  /// ===== Anyone Functions End  ====================

  /// ===== Admin Functions Start ====================

  adminUpdateWebsiteUrl = (newUrl) => {
    if(newUrl !== this.state.immutablesWEB) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.contractOwnerUpdateWebsite(newUrl).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false, immutablesWEB:newUrl })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  adminUpdateMetadataServerUrl = (newUrl) => {
    if(newUrl !== this.state.immutablesURI) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.contractOwnerUpdateAPIURL(newUrl).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false, immutablesURI: newUrl })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  adminSetUseMetadataServer = (decision) => {
    console.log(decision)
    this.state.contract.methods.contractOwnerUpdateUseMetadataServer(decision).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  adminUpdatePostingFee = (newFee) => {
    this.setState({ adminLoading: true })
    const newFeeWei = window.web3.utils.toWei(newFee);
    this.state.contract.methods.contractOwnerUpdatePostingFee(newFeeWei).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false, postingFeeWei: newFeeWei, pstingFee: newFee })
      console.log(receipt);
      window.location.reload();
    })
  }

  adminUpdatePageFee = (newFee) => {
    this.setState({ adminLoading: true })
    const newFeeWei = window.web3.utils.toWei(newFee);
    this.state.contract.methods.contractOwnerUpdatePageFee(newFeeWei).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false, pageFeeWei: newFee, pageFee: newFee })
      console.log(receipt);
      window.location.reload();
    })
  }

  contractOwnerUpdateContractTipPercentage = (percent) => {
    if(percent !== this.state.contractTipPercentage) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.contractOwnerUpdateContractTipPercentage(percent).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        console.log(receipt);
        this.setState({contractTipPercentage:percent, adminLoading: false})
        window.location.reload();
      })
    }
  }

  contractOwnerUpdatePosterTipPercentage = (percent) => {
    if(percent !== this.state.posterTipPercentage) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.contractOwnerUpdatePosterTipPercentage(percent).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        console.log(receipt);
        this.setState({posterTipPercentage:percent, adminLoading: false})
        window.location.reload();
      })
    }
  }

  contractOwnerAddTeammember = (newTeammember) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.contractOwnerAddTeammember(newTeammember).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  contractOwnerRemoveTeammember = (delTeammember) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.contractOwnerRemoveTeammember(delTeammember).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  contractOwnerUpdateCuratorAddressAndPercent = (curator, percent) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.contractOwnerUpdateCuratorAddressAndPercent(curator, percent).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      this.setState({curator:curator, curatorPercent:percent})
      window.location.reload();
    })
  }

  contractOwnerUpdateBeneficiaryAddressAndPercent = (beneficiary, percent) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.contractOwnerUpdateBeneficiaryAddressAndPercent(beneficiary, percent).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      this.setState({beneficiary:beneficiary, beneficiaryPercent:percent})
      window.location.reload();
    })
  }

  contractOwnerRoyaltyRecipientUpdateRoyaltyInfo = (tokenId, receiver, royaltyPercent) => {
    this.setState({ adminLoading: true })
    //TODO: uncomment when new contract deployed
    this.state.contract.methods.contractOwnerRoyaltyRecipientUpdateRoyaltyInfo(tokenId, receiver, royaltyPercent).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  withdraw = () => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.withdraw().send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      //window.location.reload();
      alert("Withdraw complete.")
    })
  }

  /// ===== Admin Functions End  ====================

  /// ===== Click Handler Functions Start  ====================

  onClearFilters = async (e) => {
    if(this.state.pageInput !== null) {
      this.props.history.push(`/`);
    }
    this.setState({
      filterStartBlock: 0,
      filterEndBlock: 'latest',
      filterPoster: null,
      filterTag: null,
      pageInput: null,
      posts: [],
    });
    await this.loadBlockchainData();
  }

  // This method will be sent to the child component
  accountClickHandler = async (address) => {
      this.setState({filterPoster: address, pageInput: null, posts: []});
      await this.loadBlockchainData();
  }

  tagClickHandler = async (tag) => {
      this.setState({filterTag: tag, posts: []});
      await this.loadBlockchainData();
  }

  toggleSortOldToNew = async () => {
      this.setState({sortOldToNew: !this.state.sortOldToNew, posts: []});
      await this.loadBlockchainData();
  }

  postReplyHandler = async (txhash, page) => {
    let repliesPage = ""
    const regex = /.replies$/i; // Prevent .reply from repeatedy being tacked on.
    if(page !== null) {
      repliesPage = page.replace(regex, '');
      //this.props.history.push(`/${page}.replies`);
      repliesPage = repliesPage + ".replies"
    } else if(this.state.pageInput !== null) {
      repliesPage = this.state.pageInput.replace(regex, '');
      //this.props.history.push(`/${this.state.pageInput}.replies`);
      repliesPage = repliesPage + ".replies"
    } else {
      repliesPage = ".replies"
    }

    await this.pageClickHandler(repliesPage)
    await this.setState({
                    composeFormTagContentInput: "R" + txhash,
                    composeFormContentInput: "\n\n> R" + txhash})
    this.openCreatePostModal();
  }

  postHideHandler = async (txhash) => {
    await this.setState({composeFormTagContentInput: "H" + txhash,
                         composeFormContentInput: " "})
    this.openCreatePostModal();
  }

  seeRepliesClickHandler = async (txhash, page) => {
    var citationFilterToApply = "R" + txhash

    let repliesPage = ""
    const regex = /.replies$/i; // Prevent .reply from repeatedy being tacked on.

    if(page !== null) {
      repliesPage = page.replace(regex, '');
      repliesPage = repliesPage + ".replies"
    } else {
      repliesPage = this.state.pageInput.replace(regex, '');
      repliesPage = repliesPage + ".replies"
    }

    console.log("seeRepliesClickHandler - repliesPage: ", repliesPage)
    console.log("seeRepliesClickHandler - citationFilterToApply: ", citationFilterToApply)

    this.setState({pageInput: repliesPage,
                   filterTag: citationFilterToApply})

    await this.getPostsForPageTag(repliesPage, citationFilterToApply);

    this.props.history.push(`/${repliesPage}`);
  }

  transactionClickHandler = async (transaction) => {
    this.props.history.push(`/tx/${transaction}`);
    await this.loadBlockchainData();
  }

  pageClickHandler = async (page) => {
      this.props.history.push(`/${page}`);
      await this.loadBlockchainData();
      //await window.location.reload();
  }

  getAbbreviatedHash(transactionHash, firstNumberOfDigits, lastNumberOfDigits, excludeHex) {
    let firstDigit = (excludeHex) ? 2 : 0;
    firstNumberOfDigits = (excludeHex) ? firstNumberOfDigits : firstNumberOfDigits + 2;
    return <span>{transactionHash.slice(firstDigit, firstNumberOfDigits)}...{transactionHash.slice(transactionHash.length-lastNumberOfDigits, transactionHash.length)}</span>;
  }

  getTimeline() {
    const { loading, posts, contract, pageInput, account, pageOwner, userIsContractOwner, userIsTeammember, platform} = this.state;
    let timeline;
    if(loading) {
      timeline =  <Spinner animation="grow" />
    } else {
      if(posts.length > 0) {
        timeline = <Timeline
        account={account}
        platform={platform}
        pageOwner={pageOwner}
        userIsContractOwner={userIsContractOwner}
        userIsTeammember={userIsTeammember}
        contract={contract}
        posts={posts}
        txHashClickHandler={this.getPostWithTransactionHash}
        accountClickHandler={this.accountClickHandler}
        seeRepliesClickHandler={this.seeRepliesClickHandler}
        postHideHandler={this.postHideHandler}
        postReplyHandler={this.postReplyHandler}
        pageClickHandler={this.pageClickHandler}
        transactionClickHandler={this.transactionClickHandler}
        getAbbreviatedHash={this.getAbbreviatedHash}
        setComposeFormTagContentInput={this.setComposeFormTagContentInput}
        setTipFormPostAuthorInput={this.setTipFormPostAuthorInput}
        openCreateTipModal={this.openCreateTipModal}
        pageInput={pageInput} tagClickHandler={this.tagClickHandler}
        setStyleOverride={this.setStyleOverride}
        />;
      } else {
        if(!this.state.canPostInPage && this.state.pageCanBeMinted === true) {
          console.log("")
          timeline =
          <div align='center' style={{paddingLeft: '0px', paddingTop:'100px', fontFamily: 'VT323, monospace'}}>
            <br/>
            <font size='6'>
            This Page is Available to Mint. It could be yours.
            </font>
            <br/>
            Mint it, then create a Post.
           </div>
        } else if(this.state.canPostInPage && this.state.pageOwner === this.state.account) {
          timeline = <div align='center' style={{paddingLeft: '0px', paddingTop:'100px', fontFamily: 'VT323, monospace'}}>
            <br/>
            <font size='6'>
            You own the NFT of this Page!
            </font>
            <br/>
            Create a Post!
           </div>
        } else if(this.state.canPostInPage && (this.state.userIsTeammember || this.state.userIsContractOwner)) {
           timeline = <div align='center' style={{paddingLeft: '0px', paddingTop:'100px', fontFamily: 'VT323, monospace'}}>
             <br/>
             <font size='6'>
             You are an administrator and can post in this page.
             </font>
             <br/>
             Use this sparingly to hide posts that violate Terms of Service!
            </div>
        } else if(this.state.canPostInPage) {
          timeline = <div align='center' style={{paddingLeft: '0px', paddingTop:'100px', fontFamily: 'VT323, monospace'}}>
            <br/>
            <font size='6'>
            This is a public Page, anyone can Post here.
            </font>
            <br/>
            This page cannot be purchased.
           </div>
        } else {
          timeline =
          <div align='center' style={{paddingLeft: '0px', paddingTop:'100px', fontFamily: 'VT323, monospace'}}>
            <br/>
            <font size='6'>
            An NFT of this page has been created, but you are not authorized to post here.
            </font>
           </div>
        }
      }
    }
    return timeline;
  }

  /// ===== Click Handler Functions End  ====================

  render() {

    const timeline = this.getTimeline();
    const { contract,
            loading, isConnectedToADeployedToNetwork, loadingDescription,

            platform, account,   userPages,  pageEventHistory, pageTokenId, userIsContractOwner,  pageOwner,
            pageInput, newPosts, newPages, userIsPageOwner, userIsTeammember, userIsRoyaltyRecipient, userIsPayee,

            posts, sortOldToNew,

            isAdminModalOpen, isMintPageModalOpen, isPageInfoModalOpen, isNavigateModalOpen, isCreatePostModalOpen, isCreateTipModalOpen, isAboutModalOpen, isMetamaskModalOpen, isChainModalOpen,
            currentlyPosting, ipfsUploading, currentlyMinting, adminLoading, contractBalance,

            postingFee, pageFee, contractTipPercentage, posterTipPercentage,

            pageRoyaltyAddress, pageRoyaltyPercent, pageRoyaltyContractBalance,

            useMetadataServer, immutablesWEB, immutablesURI,
            userScreeningEnabled,
            curator, curatorPercent,
            beneficiary, beneficiaryPercent,
            teammembers, allowedUsers,


            composeFormTagContentInput, composeFormContentInput, tipFormPostAuthorInput,

            filterStartBlock, filterEndBlock, filterPoster, filterTag, canPostInPage} = this.state;
    return (
      <div>
        { loading
          ? <div id="loader" className="text-center mt-5">
            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <h3 style={{ color: 'black', fontFamily: 'VT323, monospace' }}>
                {(this.state.pageInput) ? (
                  `][ ${this.state.pageInput}`
                ) : (
                  `${this.state.platform.name}`
                )}
              </h3>
            </div>

            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <Spinner animation="grow" />
            </div>

            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <h3 style={{ color: 'black', fontFamily: 'VT323, monospace' }}>
                {loadingDescription}
              </h3>
            </div>

            </div>
          : <div>
            <AboutModal
              isAboutModalOpen={isAboutModalOpen} contract={contract} platform={platform} closeAboutModal={this.closeAboutModal}
            />
            <MetamaskModal
              isMetamaskModalOpen={isMetamaskModalOpen} platform={platform} closeMetamaskModal={this.closeMetamaskModal}
            />
            <ChainModal
              isChainModalOpen={isChainModalOpen} closeChainModal={this.closeChainModal}
            />
            <SiteNavbar
            platform={platform} pageInput={pageInput} pageTokenId={pageTokenId} pageOwner={pageOwner} account={account}
            openAboutModal={this.openAboutModal} openPageInfoModal={this.openPageInfoModal} openNavigateModal={this.openNavigateModal}
            />
            <BottomBar

            userIsPageOwner={userIsPageOwner}
            userIsContractOwner={userIsContractOwner}
            userIsTeammember={userIsTeammember}
            userIsRoyaltyRecipient={userIsRoyaltyRecipient}
            userIsPayee={userIsPayee}

            filterStartBlock={filterStartBlock} filterEndBlock={filterEndBlock}
            filterPoster={filterPoster}
            filterTag={filterTag}
            pageInput={pageInput}
            canPostInPage={canPostInPage}
            openAdminModal={this.openAdminModal} openCreatePostModal={this.openCreatePostModal} onClearFilters={this.onClearFilters}
            posts={posts}
            sortOldToNew={sortOldToNew} toggleSortOldToNew={this.toggleSortOldToNew}
            />
            { isConnectedToADeployedToNetwork ?
            <div>
            <PageInfoModal
              isPageInfoModalOpen={isPageInfoModalOpen}
              contract={contract}
              platform={platform}
              pageTokenId={pageTokenId} pageInput={pageInput} pageOwner={pageOwner} pageEventHistory={pageEventHistory}
              closePageInfoModal={this.closePageInfoModal} openAboutModal={this.openAboutModal}
            />
            <MintPageModal
              account={account} pageInput={pageInput} platform={platform} currentlyMinting={currentlyMinting} pageFee={pageFee}
              isMintPageModalOpen={isMintPageModalOpen} beneficiary={beneficiary} beneficiaryPercent={beneficiaryPercent}
              closeMintPageModal={this.closeMintPageModal} openAboutModal={this.openAboutModal} mint={this.mint}
            />
            <AdminModal
              contract={contract}

              isAdminModalOpen={isAdminModalOpen} closeAdminModal={this.closeAdminModal}
              adminLoading={adminLoading}

              platform={platform}

              useMetadataServer={useMetadataServer} adminSetUseMetadataServer={this.adminSetUseMetadataServer}
              immutablesWEB={immutablesWEB} adminUpdateWebsiteUrl={this.adminUpdateWebsiteUrl}
              immutablesURI={immutablesURI} adminUpdateMetadataServerUrl={this.adminUpdateMetadataServerUrl}

              pageTokenId={pageTokenId}

              postingFee={postingFee} adminUpdatePostingFee={this.adminUpdatePostingFee}
              pageFee={pageFee} adminUpdatePageFee={this.adminUpdatePageFee}

              pageRoyaltyAddress={pageRoyaltyAddress} pageRoyaltyPercent={pageRoyaltyPercent}
              contractOwnerRoyaltyRecipientUpdateRoyaltyInfo={this.contractOwnerRoyaltyRecipientUpdateRoyaltyInfo}
              pageRoyaltyContractBalance={pageRoyaltyContractBalance}

              contractTipPercentage={contractTipPercentage}
              contractOwnerUpdateContractTipPercentage={this.contractOwnerUpdateContractTipPercentage}
              posterTipPercentage={posterTipPercentage}
              contractOwnerUpdatePosterTipPercentage={this.contractOwnerUpdatePosterTipPercentage}

              userScreeningEnabled={userScreeningEnabled}
              teamToggleUserScreeningEnabled={this.teamToggleUserScreeningEnabled}
              teamAddAllowedUser={this.teamAddAllowedUser} teamRemoveAllowedUser={this.teamRemoveAllowedUser}

              allowedUsers={allowedUsers}
              getAllowedUsers={this.getAllowedUsers}

              userIsPageOwner={userIsPageOwner}
              userIsContractOwner={userIsContractOwner}
              userIsTeammember={userIsTeammember}
              userIsRoyaltyRecipient={userIsRoyaltyRecipient}
              userIsPayee={userIsPayee}

              curator={curator} curatorPercent={curatorPercent} contractOwnerUpdateCuratorAddressAndPercent={this.contractOwnerUpdateCuratorAddressAndPercent}
              beneficiary={beneficiary} beneficiaryPercent={beneficiaryPercent} contractOwnerUpdateBeneficiaryAddressAndPercent={this.contractOwnerUpdateBeneficiaryAddressAndPercent}


              contractOwnerAddTeammember={this.contractOwnerAddTeammember}
              contractOwnerRemoveTeammember={this.contractOwnerRemoveTeammember}
              getTeammembers={this.getTeammembers}
              teammembers={teammembers}

              contractBalance={contractBalance} withdraw={this.withdraw}
            />
            <NavigateModal
              platform={platform} pageInput={pageInput} userPages={userPages} newPosts={newPosts} newPages={newPages}
              isNavigateModalOpen={isNavigateModalOpen}
              filterStartBlock={filterStartBlock} filterEndBlock={filterEndBlock} filterPoster={filterPoster} filterTag={filterTag}
              setFilterStartBlock={this.setFilterStartBlock} setFilterEndBlock={this.setFilterEndBlock}
              setFilterPoster={this.setFilterPoster} setFilterTag={this.setFilterTag}
              setPageInput={this.setPageInput} updateHistory={this.updateHistory}
              closeNavigateModal={this.closeNavigateModal} openAboutModal={this.openAboutModal}
            />
            <CreatePostModal
              account={account} platform={platform} postingFee={postingFee}
              isCreatePostModalOpen={isCreatePostModalOpen} currentlyPosting={currentlyPosting} ipfsUploading={ipfsUploading}
              beneficiary={beneficiary} beneficiaryPercent={beneficiaryPercent}
              anyonePostPageTagContent={this.anyonePostPageTagContent}

              openAboutModal={this.openAboutModal} closeCreatePostModal={this.closeCreatePostModal} getAbbreviatedHash={this.getAbbreviatedHash}
              captureFileForIPFS={this.captureFileForIPFS}
              uploadImageToIPFS={this.uploadImageToIPFS} captureFileForDataURI={this.captureFileForDataURI}

              configurePostWithStyle={this.configurePostWithStyle}

              pageInput={pageInput} setPageInput={this.setPageInput}
              composeFormTagContentInput={composeFormTagContentInput} setComposeFormTagContentInput={this.setComposeFormTagContentInput}
              composeFormContentInput={composeFormContentInput} setComposeFormContentInput={this.setComposeFormContentInput}
            />

            <CreateTipModal
              account={account} platform={platform}
              isCreateTipModalOpen={isCreateTipModalOpen} currentlyPosting={currentlyPosting}
              anyonePayPagePostPosterValue={this.anyonePayPagePostPosterValue}

              posterTipPercentage={posterTipPercentage} contractTipPercentage={contractTipPercentage}

              openAboutModal={this.openAboutModal} closeCreateTipModal={this.closeCreateTipModal} getAbbreviatedHash={this.getAbbreviatedHash}

              pageInput={pageInput}
              composeFormTagContentInput={composeFormTagContentInput}
              tipFormPostAuthorInput={tipFormPostAuthorInput}

              pageOwner={pageOwner}
              curator={curator} curatorPercent={curatorPercent}
              beneficiary={beneficiary} beneficiaryPercent={beneficiaryPercent}
            />

            <Row>
            {timeline}
            </Row>
            </div> :
            <div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <center>
                <h1>Please connect to a network with a deployed contract.</h1>
                <br/>
                <Button onClick={this.openAboutModal} variant="secondary" size='lg'>What is Immutables?</Button>
                </center>
              <br/>
            </div>}
            </div>
          }
          <style>{this.state.styleOverride}</style>
      </div>
    );
  }
}

export default App;
