import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// NO HashRouter
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// With HashRouter
// Weird things happening

import {
  HashRouter as Router, Route,
} from "react-router-dom";

ReactDOM.render(
  <Router>
    <Route exact path="/:page" component={App}/>
    <Route exact path="/tx/:txhash" component={App}/>
    <Route exact path="/:page/replyTo/:tag" component={App}/>
    <Route exact path="//replyTo/:tag" component={App}/>
    <Route exact path="/:page/poster/:address" component={App}/>
    <Route exact path="//poster/:address" component={App}/>
    <Route exact path="/" component={App}/>
  </Router>
, document.getElementById('root'));

serviceWorker.unregister();
