import React, { Component } from 'react';
import {Table} from "react-bootstrap"
import EnsResolver from "ens-resolver";

class PageEventTable extends Component{
 getDate = (ts) => {
   var a = new Date(ts * 1000);
   return a.toString();
 }

 render() {
   const { data } = this.props;

   const etherscan_base_url = "https://rinkeby.etherscan.io"

   return(
     <Table striped bordered hover responsive size="sm">
       <thead>
         <tr>
           <th>Block</th>
           <th>Event</th>
           <th>Description</th>
         </tr>
       </thead>
       <tbody>
         {
              data.map((pEvent,i) =>(
                 <tr key={i}>
                    <td><a href={`${etherscan_base_url}/block/${pEvent.blockNumber}`} target="_new">{pEvent.blockNumber}</a></td>
                    <td><a href={`${etherscan_base_url}/tx/${pEvent.transactionHash}`} target="_new">{pEvent.event}</a></td>
                    {pEvent.event === "Transfer" ? (<td>To: <a href={`${etherscan_base_url}/address/${pEvent.returnValues.to}`} target="_new">
                      <EnsResolver lookup={pEvent.returnValues.to}/> ({pEvent.returnValues.to})</a></td>) : null }
                    {pEvent.event === "AddressReservedPage" ? <td><a href={`${etherscan_base_url}/tx/${pEvent.transactionHash}`} target="_new">Transaction on Etherscan</a></td> : null}
                      {pEvent.event === "AddressBoughtPage" ? <td><a href={`${etherscan_base_url}/tx/${pEvent.transactionHash}`} target="_new">Transaction on Etherscan</a></td> : null}
                    {pEvent.event === "AddressPostedPageTagContent" ? <td><a href={`${etherscan_base_url}/tx/${pEvent.transactionHash}`} target="_new">Transaction on Etherscan</a></td> : null}
                    {pEvent.event === "Approval" ? <td>Approved: <a href={`${etherscan_base_url}/address/${pEvent.returnValues.approved}`} target="_new">
                      <EnsResolver lookup={pEvent.returnValues.approved}/> ({pEvent.returnValues.approved})</a></td> : null}
                 </tr>
              ))
         }
       </tbody>
     </Table>
   );
 }
}

export default PageEventTable;

/* Usage
let pageEventHistoryTable;
if(this.state.web3) {
  pageEventHistoryTable = <PageEventTable data={this.state.selectedPageEventHistoryData}></PageEventTable>
} else {
  pageEventHistoryTable = "";
}

pageEventHistory = async (pageId) => {
  let pageEvents = [];
  if(this.state.contract) {
    this.state.contract.getPastEvents({}, {
        fromBlock: 0,
        toBlock: 'latest'
    })
    .then(function(events){
      //console.log(events)
        for(var i=0;i<events.length;i++){
            if(events[i].returnValues.pageId == pageId || events[i].returnValues.pageId == pageId) {
              console.log(events[i]);
              //console.log(events[i].transactionHash, events[i].event, events[i].returnValues);
              pageEvents.push(events[i]);
            }
        }
    }).catch(function(error) { console.log(error) });
  }
  return pageEvents;
}
*/
