import React, { Component } from 'react';

import {CardColumns} from "react-bootstrap"
import PostCard from "../PostCard/PostCard";
import "./Timeline.css";

class Timeline extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: this.props.posts,
    }
  }

  updateAndNotify = async () => {
    this.setState({
      posts: this.props.posts
    });

    try {
      let style = await this.getLastStyle();
      this.props.setStyleOverride(style);
    } catch (error) {
      console.error(error);
    }
  }

  componentDidMount() {
    this.updateAndNotify();
    console.log("Timeline - componentDidMount: ", this.state.posts)
  }

  componentDidUpdate(prevProps) {
    //console.logs.length = 0;

    if (prevProps.posts !== this.props.posts) {
      this.updateAndNotify();
    }
  }


  getLastStyle = async () => {
    const { posts } = this.state;

    let styleOverride = "";
    try {
      for (let i=posts.length-1; i>=0; i--) {
          if(posts[i].returnValues.tagHash === window.web3.utils.keccak256("style")) {
            styleOverride = posts[i].returnValues.content;
            break;
          }
      }
    } catch (error) {
      console.log(error);
    }
    return styleOverride;
  }

  render() {
    const { contract, platform, posts, txHashClickHandler, accountClickHandler, account, pageOwner, userIsContractOwner, userIsTeammember,
      seeRepliesClickHandler, postReplyHandler, pageClickHandler, postHideHandler, tagClickHandler,
      transactionClickHandler, getAbbreviatedHash, pageInput,
      setComposeFormTagContentInput, setTipFormPostAuthorInput, openCreateTipModal} = this.props;
    const rows = [];

    try {
      for (let i=0; i<posts.length; i++) {
        rows.push(<li key={i}><PostCard
          platform={platform}
          account={account}
          pageOwner={pageOwner}
          userIsContractOwner={userIsContractOwner}
          userIsTeammember={userIsTeammember}
          contract={contract}
          post={posts[i]}
          txHashClickHandler={txHashClickHandler}
          accountClickHandler={accountClickHandler}
          seeRepliesClickHandler={seeRepliesClickHandler}
          postHideHandler={postHideHandler}
          postReplyHandler={postReplyHandler}
          pageClickHandler={pageClickHandler}
          transactionClickHandler={transactionClickHandler}
          getAbbreviatedHash={getAbbreviatedHash}
          setComposeFormTagContentInput={setComposeFormTagContentInput}
          setTipFormPostAuthorInput={setTipFormPostAuthorInput}
          openCreateTipModal={openCreateTipModal}
          pageInput={pageInput}
          tagClickHandler={tagClickHandler}
          /></li>);
      }
    } catch (error) {
      console.log(error);
    }

    return (
        <div className="Timeline">
          <ul className="no-bullets">
            <CardColumns>
              {rows}
            </CardColumns>
          </ul>
        </div>
    );
  }
}

export default Timeline;
