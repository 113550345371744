import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

class ChainModal extends Component {

  render() {

    const { isChainModalOpen, closeChainModal } = this.props;
    
    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
      size="lg"
      show={isChainModalOpen}
      onHide={closeChainModal}
      >
        <Modal.Header>
          <Modal.Title style={{fontFamily: 'VT323, monospace'}}>Check Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        To use Immutables, please connect to the Rinkeby chain and reload the page.
        </Modal.Body>
      </Modal>
    );
  }
}

export default ChainModal;