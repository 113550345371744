import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import MDEditor from '@uiw/react-md-editor';
import EnsResolver from "ens-resolver";
import PageEventTable from './PageEventTable/PageEventTable';

class PageInfoModal extends Component {

  render() {

    const { contract, isPageInfoModalOpen, platform, pageTokenId, pageInput, pageOwner, pageEventHistory,
          closePageInfoModal, openAboutModal } = this.props;

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        size="lg" show={isPageInfoModalOpen} onHide={closePageInfoModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: 'VT323, monospace'}}>Page "{pageInput}" Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MDEditor.Markdown source={platform.page_detials_modal} />
            <p></p>
            <div>
            <b>Immutables Page TokenID:</b> {pageTokenId}
            </div>
            {(pageOwner) ? (
              <div>
              <b>Current Page Owner:</b> <EnsResolver lookup={pageOwner}/> ({pageOwner})
              </div>
            ) : ("Cannot determine owner for this Page.")}
            <p></p>
            { (pageEventHistory.length > 0) ? (
              <PageEventTable data={pageEventHistory}></PageEventTable>
            ) : ("This Page has No Event History.")}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={openAboutModal}>
              Help / WTF Is This Website?
            </Button>
            <Button variant="outline-secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={() => window.open(`${platform.etherscan_token_link}${contract.options.address}${platform.etherscan_token_query_after_contract}${pageTokenId}`)} target="_new">
            Etherscan
            </Button>
            <Button variant="outline-secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={() => window.open(`${platform.opensea_asset_base_link}${contract.options.address}/${pageTokenId}`)} target="_new">
            OpenSea
            </Button>
            <Button variant="secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={closePageInfoModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
}

export default PageInfoModal;
