import React, { Component } from 'react';
import { Modal, Container, Row, Form, InputGroup, Button, Spinner, Table, Tabs, Tab} from "react-bootstrap";
import MDEditor from '@uiw/react-md-editor';
import EnsResolver from "ens-resolver";
import FileBase64 from 'react-file-base64';

class CreatePostModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pageInput: this.props.pageInput,
      composeFormTagContentInput: this.props.composeFormTagContentInput,
      composeFormContentInput: this.props.composeFormContentInput,
    };
  }

  updateAndNotify = () => {
    this.setState({
      pageInput: this.props.pageInput,
      composeFormTagContentInput: this.props.composeFormTagContentInput,
      composeFormContentInput: this.props.composeFormContentInput
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.pageInput !== this.props.pageInput || prevState.composeFormTagContentInput !== this.props.composeFormTagContentInput) {
      this.updateAndNotify();
    }
  }

  render() {

    const {account, platform, postingFee,
      isCreatePostModalOpen, currentlyPosting, ipfsUploading,
      beneficiary, beneficiaryPercent,
      anyonePostPageTagContent, setComposeFormTagContentInput, setPageInput,
      openAboutModal, closeCreatePostModal,
      captureFileForIPFS, uploadImageToIPFS, captureFileForDataURI, configurePostWithStyle} = this.props;

      // TODO: This does not work here.  The values are not passed to the form.
      // It works initially if these are moved up to the = this.props line.
      // However, then they cannot be edited (which defeats purpose of form ! :)
    const { pageInput, composeFormContentInput, composeFormTagContentInput } = this.state;

    console.log("createPost - render - pageInput: ", pageInput);
    console.log("createPost - render - composeFormContentInput: ", composeFormContentInput);
    console.log("createPost - render - composeFormTagContentInput: ", composeFormTagContentInput);

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isCreatePostModalOpen} onHide={closeCreatePostModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'VT323, monospace'}}>Post as <b><EnsResolver lookup={ account }/></b> {(account) ? `(${account.slice(0,7)}...${account.slice(account.length-8, account.length)})` : ("")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <Tabs defaultActiveKey="compose" id="uncontrolled-tab-example">
                <Tab eventKey="compose" title="Compose Immutable Post">
                <p></p><MDEditor.Markdown source={platform.compose_intro} />
                <p></p>
                <Container>
                  <Row className="justify-content-md-center">
                  {/*}<div className="col-lg-12 d-flex text-center">
                    <div className="content mr-auto ml-auto">*/}

                      <form onSubmit={(event) => {
                        event.preventDefault()
                        const newPage = pageInput
                        const newTag = ((composeFormTagContentInput===undefined) || (composeFormTagContentInput===null)) ? "" : composeFormTagContentInput
                        const newText = composeFormContentInput
                        anyonePostPageTagContent(newPage, newTag, newText)}
                        //this.closeCreatePostModal()
                        //this.setState({composeFormTagContentInput: null, composeFormContentInput: null})
                        //{setComposeFormTagContentInput(null)}
                        //{setComposeFormContentInput(null)}
                      }>

                      { (currentlyPosting) ? (
                        <>
                        <div align="center">
                        <p><b>Please wait while the post is added to the Blockchain ...</b></p>
                          <Spinner animation="grow" />
                          </div>
                        </>
                      ) : (
                        <>

                      <Form.Group controlId="newPost">
                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>Page</InputGroup.Text>
                            <Form.Control required type="text" defaultValue={pageInput}
                                //onChange={e => this.setState({ pageInput: e.target.value })}
                                onChange={e => setPageInput(e.target.value) }
                                />
                            <Form.Text className="text-muted">
                            </Form.Text>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>Tag</InputGroup.Text>
                            <Form.Control type="text" defaultValue={composeFormTagContentInput}
                                //onChange={e => this.setState({ composeFormTagContentInput: e.target.value })}
                                onChange={e => setComposeFormTagContentInput(e.target.value) }
                                />
                            <Form.Text className="text-muted">
                            </Form.Text>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="mb-2">
                          <div className="container" style={{width: "100%"}}>
                            <MDEditor
                              preview='edit'
                              value={composeFormContentInput}
                              onChange={e => this.setState({ composeFormContentInput: e })}
                            />
                          </div>
                          </InputGroup>
                        </Form.Group>
                      </Form.Group>
                      <Table>
                      <Row>
                          <MDEditor.Markdown
                            source={platform.compose_caution}
                            preview='edit'
                          />
                          <p></p>
                          <Row>
                          <div align="center">
                          <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="I agree to the Posting Terms and Conditions" required/>
                          </Form.Group>
                          </div>
                          </Row>
                          <input
                          id="anyonePostPageTagContentSubmit"
                          type="submit"
                          className="btn btn-block btn-success"
                          style={{fontFamily: 'VT323, monospace'}}
                          value={`Add Content and Submit Post (${postingFee}Ξ + gas)`}
                        />
                        {((postingFee > 0) && (beneficiaryPercent>0)) ? (
                          <>
                          <p></p>
                          <div align="center" style={{fontSize: "8pt"}}>
                          We donate {beneficiaryPercent}% of the collected fees to {platform.cause} <EnsResolver lookup={beneficiary}/> ({beneficiary}).
                          </div>
                          </>) : ("")}
                        </Row>
                        </Table>
                        </>
                        )}
                      </form>
                      </Row>
                      </Container>
                    {/*}</div>
                  </div>*/}
                </Tab>

                {/*
                <Tab eventKey="compose_image_datauri" title="Add On Chain Image">
                  <p></p><MDEditor.Markdown source={platform.compose_on_chain_image_before_upload} />
                  <p></p>
                  <FileBase64
                  onDone = { captureFileForDataURI }
                  />
                  <p></p><MDEditor.Markdown source={platform.compose_on_chain_image_after_upload} />
                </Tab>
                */}

                {/*
                <Tab eventKey="compose_image_ipfs" title="Add IPFS Image">
                  <p></p><MDEditor.Markdown source={platform.compose_ipfs_image} />
                  <p></p>
                  <form
                  onSubmit={(event) => {
                    event.preventDefault()
                    uploadImageToIPFS()
                  }}
                  >
                  { (ipfsUploading) ? (
                    <Row>
                    <div align="center"><Spinner animation="grow" /></div>
                    </Row>
                  ) : (
                    <>
                    <Row>
                    <input type='file' accept=".jpg, .jpeg, .png, .bmp, .gif, .svg" onChange={captureFileForIPFS}/>
                  </Row>
                  <p></p>
                  <Row>
                    <Button variant="success"
                      type="submit"
                      style={{ fontFamily: 'VT323, monospace' }}>
                        Store on IPFS and Insert into Compose Tab
                    </Button>
                  </Row>
                  </>
                )}
                  </form>
                </Tab>
                */}

                <Tab eventKey="compose_style_css" title="Update Page CSS Style">
                  <p></p><MDEditor.Markdown source={platform.compose_style_css} />
                  <p></p>
                  <form
                  onSubmit={(event) => {
                      event.preventDefault()
                      configurePostWithStyle()
                    }
                  }
                  >
                    <>
                  <p></p>
                  <Row>
                    <Button variant="success"
                      type="submit"
                      style={{ fontFamily: 'VT323, monospace' }}>
                        Insert Example CSS into Compose Tab
                    </Button>
                  </Row>
                  </>
                  </form>
                </Tab>
              </Tabs>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="warning btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={openAboutModal}>
                  Posting Terms and Conditions
                </Button>
                <Button variant="info btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={openAboutModal}>
                  Help / WTF Is This Website?
                </Button>
                <Button variant="secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={closeCreatePostModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default CreatePostModal;
