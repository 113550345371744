import React, { Component } from 'react';
import { Button, Form, Modal, Spinner, Table, Row } from "react-bootstrap";
import MDEditor from '@uiw/react-md-editor';
import EnsResolver from "ens-resolver";

class MintPageModal extends Component {

  render() {
    const { account, pageInput, platform, currentlyMinting, pageFee,
      isMintPageModalOpen,
      beneficiary, beneficiaryPercent,
      closeMintPageModal, openAboutModal, mint } = this.props;

      return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isMintPageModalOpen} onHide={closeMintPageModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'VT323, monospace'}}>Mint Page "{pageInput}"</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p></p>
                <MDEditor.Markdown source={platform.mintImmutableModalText} />
                <p></p>
                { (currentlyMinting) ? (
                  <div align="center">
                  <p>Please wait while the page is minted...</p>
                  <Spinner animation="grow" />
                  </div>
                ) : (
                  <div align="center">
                  You can mint this page!
                  <p></p>
                  <b>Mint as <EnsResolver lookup={account}/> ({account})</b>
                  <p></p>
                  <Form className="d-flex" onSubmit={mint}>
                  <Table>
                  <Row>
                  <div align="center">
                  <Form.Group id="formGridCheckbox">
                    <Form.Check type="checkbox" label="I agree to the Minting Terms and Conditions" required/>
                  </Form.Group>
                  </div>
                  </Row>
                  <Row>
                  <input
                      id="submit"
                      type="submit"
                      className="btn btn-block btn-success"
                      style={{fontFamily: 'VT323, monospace'}}
                      value={`Mint Page "${pageInput}" into a NFT (${pageFee}Ξ + gas)`}
                    />
                    {((pageFee > 0) && (beneficiaryPercent>0)) ? (
                      <>
                      <p></p>
                      <div align="center" style={{fontSize: "8pt"}}>
                      We donate {beneficiaryPercent}% of the collected fees to {platform.cause} <EnsResolver lookup={beneficiary}/> ({beneficiary}).
                      </div>
                      </>) : ("")}
                    </Row>
                    </Table>
                  </Form>
                  </div>
                )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={openAboutModal}>
            Minting Terms and Conditions
          </Button>
          <Button variant="info btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={openAboutModal}>
            Help / WTF Is This Website?
          </Button>
          <Button variant="secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={closeMintPageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MintPageModal;
