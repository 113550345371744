import React, { Component } from 'react';
import { Nav, Navbar, Button} from "react-bootstrap";

class SiteNavbar extends Component {

  render() {

    const { platform, pageInput, pageTokenId, pageOwner, account,
      openAboutModal, openPageInfoModal, openNavigateModal } = this.props;

    let siteNavbar;
    if(!window.web3) { // If not web3
        siteNavbar = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="top">
                <Navbar.Brand style={{ fontFamily: 'VT323, monospace' }} onClick={openAboutModal}>{platform.name}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              </Navbar>
    } else { // If using web3
        siteNavbar = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="top">
                  { (pageInput) ? (
                    <>
                    <div>
                    &nbsp;
                    <Button variant="outline-secondary" style={{ fontFamily: 'VT323, monospace' }} onClick={openAboutModal}>{platform.symbol}</Button>
                    &nbsp;
                    {(pageOwner === account) ? (
                      <Button variant="success" style={{ fontFamily: 'VT323, monospace' }} onClick={openPageInfoModal}>{pageInput}</Button>
                    ) : (
                      <Button variant="secondary" style={{ fontFamily: 'VT323, monospace' }} onClick={openPageInfoModal}>{pageInput}</Button>
                    )}
                    </div>
                    </>
                  ):(
                    <Button variant="info" style={{ fontFamily: 'VT323, monospace' }} onClick={openAboutModal}>{platform.name}</Button>
                  )}
                  <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                  </Navbar.Collapse>
                  {(pageTokenId > 0) ? (
                  <Nav.Item>
                  {/*
                  <Button variant="outline-secondary" style={{ fontFamily: 'VT323, monospace' }} href={`${this.state.platform.opensea_asset_base_link}/${this.state.pageTokenId}`} target="_new">
                    OpenSea
                  </Button>
                  &nbsp;
                  */}
                  </Nav.Item>
                  ):("")}
                  <Nav.Item>
                  <Button variant="secondary" style={{ fontFamily: 'VT323, monospace' }} onClick={openNavigateModal}>
                    Navigate
                  </Button>
                  &nbsp;
                  </Nav.Item>
              </Navbar>
    }
    return siteNavbar;
  }
}

export default SiteNavbar;
