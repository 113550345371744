import React, { Component } from 'react';
import { Nav, Navbar, Button} from "react-bootstrap";

class BottomBar extends Component {

  render() {

    const { userIsContractOwner, userIsTeammember, userIsPayee, userIsRoyaltyRecipient,

            filterStartBlock, filterEndBlock, filterPoster, filterTag, canPostInPage, pageInput, userIsPageOwner,
            openAdminModal, openCreatePostModal, onClearFilters, posts, sortOldToNew, toggleSortOldToNew } = this.props;

    let bottomBar;
    if(!window.web3) {
      bottomBar = (<Navbar collapseOnSelect bg="light" expand='sm'  variant="light" fixed="bottom">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto justify-content-center">
                    Get MetaMask to Use ][mmutables.
                    </Nav>
                  </Navbar.Collapse>
              </Navbar>);
    } else {
      bottomBar = (<Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="bottom">
                    {/*<Navbar.Toggle aria-controls="responsive-navbar-nav" />*/}
                      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                      </Navbar.Collapse>
                      {(userIsContractOwner || userIsTeammember || userIsPayee || userIsPageOwner || userIsRoyaltyRecipient) ? (<Nav.Item>
                        <Button variant="warning"
                          style={{ fontFamily: 'VT323, monospace' }}
                          onClick={openAdminModal}>
                            Admin
                          </Button>
                          &nbsp;
                          </Nav.Item>) : ("")}
                          { ((pageInput !== null) || (filterStartBlock > 0) || (filterEndBlock !== "latest") || (filterPoster !== null) || (filterTag !== null)) ? (
                          <Nav.Item>
                            <Button variant="primary" style={{ fontFamily: 'VT323, monospace' }}
                            //onClick={(event) => {this.setState({filterStartBlock: 0, filterEndBlock: 'latest', filterPoster: null, filterTag: null}, this.loadBlockchainData);}}
                            onClick={onClearFilters}
                            >
                              Clear Filters
                            </Button>
                            &nbsp;
                          </Nav.Item>
                          ) : ("")}
                          { (posts && posts.length > 1) ?
                            (sortOldToNew) ? (
                              <Nav.Item>
                                <Button variant="outline-primary" style={{ fontFamily: 'VT323, monospace' }} onClick={(event) => {
                                    toggleSortOldToNew();
                                  }}>
                                  Sorting Old to New
                                </Button>
                                &nbsp;
                              </Nav.Item>
                              ) : (
                                <Nav.Item>
                                  <Button variant="outline-primary" style={{ fontFamily: 'VT323, monospace' }} onClick={(event) => {
                                      toggleSortOldToNew();
                                    }}>
                                    Sorting New to Old
                                  </Button>
                                  &nbsp;
                                </Nav.Item>
                              )
                            : ("")}
                          {(canPostInPage && pageInput !== null) ? (
                          <Nav.Item>
                            <Button variant="success"
                              style={{ fontFamily: 'VT323, monospace' }}
                              onClick={openCreatePostModal}>
                                Add Post to Page
                              </Button>
                              &nbsp;
                        </Nav.Item>
                      ):("")}
                      </Navbar>);
    }
    return bottomBar;
  }
}

export default BottomBar;
