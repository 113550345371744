import React, { Component } from 'react';
//import ReactDOM from "react-dom";

//import Jdenticon from 'react-jdenticon';
import MDEditor from '@uiw/react-md-editor';

import EnsResolver from "ens-resolver";

import {Card, Badge, Button, OverlayTrigger, Tooltip, CardColumns} from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import Table from 'react-bootstrap/Table'

import "./PostCard.css";

//var humanhash = new (require("humanhash"))()
//{humanhash.humanize(this.props.post.transactionHash)}
// Can this go the other way?

// TODO: anyonePayPagePostPosterValue -- button and visualization


class PostCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      post: this.props.post,
      showReplies: false,
      replies: [],
      replyCards: [],
      showTips: false,
      tips: [],
      tipCards: [],
      isHidden: false,
    }
  }

  componentWillUnmount = async () => {

  }

  componentDidMount = async (props) => {
    const { post } = this.state;
    try {
      await this.getAllReplies(post.transactionHash);
      await this.getAllTips(post.transactionHash);
      await this.getIsHidden(post.transactionHash);
      if(post.returnValues.content === " ") {
        this.setState({isHidden:true})
      }
      //this.setState({replies});
    } catch (error) {
      console.error(error);
    }

    console.log("postCard - componentDidMount: ", post)
  }

  toggleShowReplies = async () => {
    this.setState({showReplies: !this.state.showReplies})
  }

  getIsHidden = async (transactionHash) => {
      const web3 = window.web3
      let instance = this.props.contract;

      let isHidden = this.state.isHidden;

      try {
        const thisReplyTagHash = "H" + transactionHash;
        //console.log("postCard - getAllReplies - thisReplyTagHash: ", thisReplyTagHash)

        await instance.getPastEvents('AddressPostedPageTagContent', {
            filter: {tagHash: web3.utils.keccak256(thisReplyTagHash)},
            fromBlock: 0,
            toBlock: 'latest'
        })
        .then(async function(events){
          for(var i=0;i<events.length;i++){
              console.log("postCard - getIsHidden: ", events[i]);
              //replies.push(events[i]);

              await web3.eth.getTransaction(events[i].transactionHash, function(err, tx) {
                  console.log(tx);
                  let tx_data = tx.input;
                  let input_data = '0x' + tx_data.slice(10);  // get only data without function selector

                  let params = web3.eth.abi.decodeParameters(['string', 'string', 'string'], input_data);
                  console.log("Params: ", params);

                  let post = {
                    transactionHash: tx.hash,
                    blockNumber: tx.blockNumber,
                    returnValues: {
                      content: params[2],
                      page: params[0],
                      poster: tx.from,
                    }
                  };

                  console.log("postCard - getIsHidden - replies: ", post)
                  isHidden = true;
              }).catch(function(error) { console.log(error) });
          }
        }).catch(function(error) { console.log(error) });
      } catch (error) {
        console.error(error);
      }

      this.setState({isHidden});
  }

  getAllReplies = async (transactionHash) => {
      const web3 = window.web3
      let instance = this.props.contract;

      let replies = [];
      let replyCards = [];

      try {
        const thisReplyTagHash = "R" + transactionHash;
        //console.log("postCard - getAllReplies - thisReplyTagHash: ", thisReplyTagHash)

        await instance.getPastEvents('AddressPostedPageTagContent', {
            filter: {tagHash: web3.utils.keccak256(thisReplyTagHash)},
            fromBlock: 0,
            toBlock: 'latest'
        })
        .then(async function(events){
          for(var i=0;i<events.length;i++){
              console.log("postCard - getAllReplies: ", events[i]);
              //replies.push(events[i]);

              await web3.eth.getTransaction(events[i].transactionHash, function(err, tx){
                  console.log(tx);
                  let tx_data = tx.input;
                  let input_data = '0x' + tx_data.slice(10);  // get only data without function selector

                  let params = web3.eth.abi.decodeParameters(['string', 'string', 'string'], input_data);
                  console.log("Params: ", params);

                  let post = {
                    transactionHash: tx.hash,
                    blockNumber: tx.blockNumber,
                    returnValues: {
                      content: params[2],
                      page: params[0],
                      tag: params[1],
                      poster: tx.from,
                    }
                  };

                  replies.push(post)
              }).catch(function(error) { console.log(error) });
          }
            //this.setState({posts});
        }).catch(function(error) { console.log(error) });
      } catch (error) {
        console.error(error);
      }

      //if(!this.state.sortOldToNew) {
        replies = replies.sort((a,b) => b.blockNumber - a.blockNumber )
      //}

      try {
        for (let i=0; i<replies.length; i++) {
          replyCards.push(<li key={i}><PostCard
            style={{leftMargin: "20%", width: "70%"}}
            account={this.props.account}
            pageOwner={this.props.pageOwner}
            userIsContractOwner={this.props.userIsContractOwner}
            userIsTeammember={this.props.userIsTeammember}
            contract={this.props.contract}
            post={replies[i]}
            txHashClickHandler={this.props.txHashClickHandler}
            accountClickHandler={this.props.accountClickHandler}
            seeRepliesClickHandler={this.props.seeRepliesClickHandler}
            postHideHandler={this.props.postHideHandler}
            postReplyHandler={this.props.postReplyHandler}
            pageClickHandler={this.props.pageClickHandler}
            transactionClickHandler={this.props.transactionClickHandler}
            getAbbreviatedHash={this.props.getAbbreviatedHash}
            setComposeFormTagContentInput={this.props.setComposeFormTagContentInput}
            setTipFormPostAuthorInput={this.props.setTipFormPostAuthorInput}
            openCreateTipModal={this.props.openCreateTipModal}
            pageInput={this.props.pageInput}
            /></li>);
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({replies, replyCards});
  }

  toggleShowTips = async () => {
    this.setState({showTips: !this.state.showTips})
  }

  getAllTips = async (transactionHash) => {
      const web3 = window.web3
      let instance = this.props.contract;

      let tips = [];
      let tipCards = [];

      try {
        const thisTipTagHash = "V" + transactionHash;
        //console.log("postCard - getAllReplies - thisReplyTagHash: ", thisReplyTagHash)

        await instance.getPastEvents('AddressPaidPageTagValue', {
            filter: {tagHash: web3.utils.keccak256(thisTipTagHash)},
            fromBlock: 0,
            toBlock: 'latest'
        })
        .then(async function(events){
          for(var i=0;i<events.length;i++){
              console.log("postCard - getAllTips: ", events[i]);
              //replies.push(events[i]);

              await web3.eth.getTransaction(events[i].transactionHash, function(err, tx){
                  console.log("postCard - getAllTips - transaction: ",tx);
                  let tx_data = tx.input;
                  let input_data = '0x' + tx_data.slice(10);  // get only data without function selector

                  let params = web3.eth.abi.decodeParameters(['string', 'string', 'address'], input_data);
                  console.log("postCard - getAllTips - decodeParams: ", params);

                  let tip = {
                    transactionHash: tx.hash,
                    blockNumber: tx.blockNumber,
                    returnValues: {
                      tipper: tx.from,
                      page: params[0],
                      postTransactionHash: params[1],
                      poster: params[2],
                      value: tx.value,
                    }
                  };

                  tips.push(tip)
              }).catch(function(error) { console.log(error) });
          }
            //this.setState({posts});
        }).catch(function(error) { console.log(error) });
      } catch (error) {
        console.error(error);
      }

      //if(!this.state.sortOldToNew) {
        tips = tips.sort((a,b) => b.blockNumber - a.blockNumber )
      //}

      let totalTipsInEth = 0;

      try {
        for (let i=0; i<tips.length; i++) {
          const tipInEth = await web3.utils.fromWei(tips[i].returnValues.value, 'ether');
          totalTipsInEth += parseFloat(tipInEth);
          tipCards.push(
            <li key={i}>
              <Card style={{marginLeft: "10%", width: "80%"}}>
                <Button variant="outline-secondary" size='sm' href={`https://rinkeby.etherscan.io/tx/${tips[i].transactionHash}`} target="_new">
                  <EnsResolver lookup={tips[i].returnValues.tipper}/> ({tips[i].returnValues.tipper}) tipped {tipInEth}Ξ.
                </Button>
              </Card>
              <p></p>
            </li>);
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({tips, tipCards, totalTipsInEth});
  }

  getPostDetails ()
  {
    const { post, replyCards, tips, tipCards, totalTipsInEth } = this.state;

    const { platform, pageInput, account, pageOwner, userIsContractOwner, userIsTeammember,
            accountClickHandler, transactionClickHandler, pageClickHandler, tagClickHandler,
            postReplyHandler, postHideHandler,
            setComposeFormTagContentInput, setTipFormPostAuthorInput, openCreateTipModal
          } = this.props;


    const buttonShowReplies = (replyCards && replyCards.length > 0) ? ( // If there are replies show we have replies.
                                (post.returnValues.page) ? ( // if we have a page feed it back
                                    <>
                                    <Button variant="success" size='sm'
                                    onClick={() => this.toggleShowReplies()}>
                                    {/*onClick={() => seeRepliesClickHandler(post.transactionHash, null)}>*/}
                                    { (this.state.showReplies) ? ("Hide") : ("Show") } {replyCards.length} Repl{(replyCards.length === 1)?"y":"ies"}
                                    </Button>
                                    </>
                                  ):( // and if we dont have a page
                                    <>
                                    <Button variant="success" size='sm'
                                    onClick={() => this.toggleShowReplies()}>
                                    {/*onClick={() => seeRepliesClickHandler(post.transactionHash, null)}>*/}
                                    { (this.state.showReplies) ? ("Hide") : ("Show") } {replyCards.length} Repl{(replyCards.length === 1)?"y":"ies"}
                                    </Button>
                                    </>
                                  )
                              ): ("")

    const buttonPostReply = (post.returnValues.page) ? (
                                // if we have a page feed it back
                                <Button variant="success" size='sm'
                                onClick={() => postReplyHandler(post.transactionHash, post.returnValues.page)}>
                                Post a Reply
                                </Button>
                              ):( // if we dont dont worry
                              <OverlayTrigger
                                overlay={<Tooltip id='tooltip-disabled'>Go to the Immutables Page for this Post to Reply</Tooltip>}
                                placement='top'
                              >
                              <Button variant="outline-success" size='sm'>
                              Post a Reply
                              </Button>
                              </OverlayTrigger>
                            )

    const buttonShowTips = (tipCards && tipCards.length > 0) ? ( // If there are replies show we have replies.
                              <>
                              <Button variant="success" size='sm'
                              onClick={() => this.toggleShowTips()}>
                              {/*onClick={() => seeRepliesClickHandler(post.transactionHash, null)}>*/}
                              { (this.state.showTips) ? ("Hide") : ("Show") } {tips.length} Tip{(tipCards.length === 1)?"":"s"} ({totalTipsInEth}Ξ)
                              </Button>
                              </>
                            ): ("")

    const buttonTipPost = (post.returnValues.page || pageInput) ? (
                              <Button variant="success" size='sm'
                                onClick={async () => {
                                  await setComposeFormTagContentInput(post.transactionHash);
                                  await setTipFormPostAuthorInput(post.returnValues.poster);
                                  console.log("clickTip: ", post);
                                  openCreateTipModal(post);
                                }}>
                                Tip Post
                              </Button>
                          ) : (
                              <OverlayTrigger
                                overlay={<Tooltip id='tooltip-disabled'>Go to the Immutables Page for this Post to Tip</Tooltip>}
                                placement='top'
                              >
                              <Button variant="outline-success" size='sm'>
                              Tip Post
                              </Button>
                              </OverlayTrigger>
                          )
    const buttonHidePost = (account === pageOwner || userIsContractOwner || userIsTeammember) ? (
                            <Button variant="warning" size='sm'
                                onClick={() => postHideHandler(post.transactionHash)}>
                                Hide Post
                              </Button>
                            ) : ("")
    
    const buttonGoToPage = (post.returnValues.page) ? (
                                <Button variant="success" size='sm'
                                onClick={() => pageClickHandler(post.returnValues.page)}>
                                Go To Page
                                </Button>
                            ) : ("")
    return (
      <Table bordered responsive size='sm'>
              <tbody>
              <tr>
                <th>
                  Author:
                </th>
                <td>
                  <b><EnsResolver lookup={post.returnValues.poster}/></b>
                  &nbsp;
                  <font style={{fontSize:"small"}}>{post.returnValues.poster}</font>
                  {/*<OverlayTrigger
                  overlay={<Tooltip id='tooltip-disabled'>{post.returnValues.poster}</Tooltip>}
                  placement='top'
                  >
                  <Button variant='outline-dark' size='sm'>
                    {abbreviatedHash}
                  </Button>
                  </OverlayTrigger>*/}
                  &nbsp;
                  <Button variant="primary" size='sm' onClick={() => accountClickHandler(post.returnValues.poster)}>
                    Filter by Author
                  </Button>
                </td>
              </tr>

              {(post.returnValues.page) ? (
                <>
              <tr>
                <th>
                  Page:
                </th>
                <td>
                  <Button variant="primary" size='sm' onClick={() => pageClickHandler(post.returnValues.page)}>
                    {post.returnValues.page}
                  </Button>
                </td>
              </tr>
              <tr>
                <th>
                  Tag:
                </th>
                <td>
                  {(post.returnValues.tag) ? (
                  <Button variant="primary" size='sm' onClick={() => tagClickHandler(post.returnValues.tag)}>
                    {post.returnValues.tag}
                  </Button>):("")}
                </td>
              </tr>
              </>
            ):(
              <>
              <tr>
                <th>
                  PageHash:
                </th>
                <td>
                  <font style={{fontSize:"small"}}>{post.returnValues.pageHash}</font>&nbsp;
                </td>
              </tr>
              <tr>
                <th>
                  TagHash:
                </th>
                <td>
                  <font style={{fontSize:"small"}}>{post.returnValues.tagHash}</font>&nbsp;
                </td>
              </tr>
              </>
            )}

              <tr>
                <th>
                  Transaction:
                </th>
                <td>
                  <font style={{fontSize:"small"}}>{post.transactionHash}</font>&nbsp;
                  <Button variant="info" size='sm' className='smallButton'
                  onClick={() => transactionClickHandler(post.transactionHash)}>
                  Permalink to This Post
                  </Button>
                </td>
              </tr>
              <tr>
                <th>
                  Etherscan:
                </th>
                <td>
                <Button variant="secondary" size='sm' href={`${platform.etherscan_tx_link}${post.transactionHash}`} target="_new">
                Transaction Details
                </Button>
                &nbsp;
                <Button variant="secondary" size='sm' href={`${platform.etherscan_block_link}${post.blockNumber}`} target="_new">
                Block {post.blockNumber} Details
                </Button>
                </td>
              </tr>
              <tr>
                <th>
                  Actions:
                </th>
                <td>
                  {/*}
                    { buttonShowReplies }
                    {(true) ? (" "):("")}
                    { buttonPostReply }
                    {(post.returnValues.page) ? (" "):("")}
                    { buttonGoToPage }
                    {(true) ? (" "):("")}
                    { buttonShowTips }
                    {(true) ? (" "):("")}
                    { buttonTipPost } */}
                    { buttonGoToPage }
                    {(true) ? (" "):("")}
                    { buttonHidePost }
                  </td>
                </tr>
                </tbody>
              </Table>
    );
  }

  render() {

    const { post, replyCards, showReplies, tipCards, showTips } = this.state;
    const { getAbbreviatedHash } = this.props;

    let isHidden = this.state.isHidden;
    var isStyle = false;
    var postText = post.returnValues.content

    const reply_regex = /> R(0x.*)/i;
    postText = postText.replace(reply_regex, '[See Parent Post](/#/tx/$1)');

    const ipfs_regex = /ipfs:\/\/(.*)\/(.*)/g;
    postText = postText.replace(ipfs_regex, 'https://$1.ipfs.dweb.link/$2');

    const ipfs_regex2 = /ipfs:\/\/(.*)\)/g;
    postText = postText.replace(ipfs_regex2, 'https://$1.ipfs.dweb.link/)');

    // TODO: Make more intelligent so it doesnt capture text within a triple tick code block
    /*
    const immutables_page_regex = /\]\[\((.+?)\)/g;
    try {
      let immutables_page = immutables_page_regex.exec(postText)[1];
      //console.log("immutables_page: ", immutables_page)
      const space_regex = / /g;
      let immutables_page_url = immutables_page.replace(space_regex, `%20`)
      let new_text = `[${immutables_page}](/#/${immutables_page_url})`
      postText = postText.replace(immutables_page_regex, new_text);
    } catch (error) {  }
    //postText = postText.replace(immutables_page_regex, `[$1](/#/$1)`);
    */
    
    if(post.returnValues.tagHash === window.web3.utils.keccak256("style")) {
      isStyle = true;
      //this.props.setStyleOverride(post.returnValues.content)
      isHidden = true;
    }

    return (
      (!isHidden) ? (
      <div>
      <Accordion defaultActiveKey="0" className='postCard' id={post.transactionHash}>
        <Card>
          <Accordion eventKey="0">
            <MDEditor.Markdown source={postText} style={{width: "95%", margin: "2.5%"}}/>
          </Accordion>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Footer} eventKey="1">
          <table style={{width: "100%", margin: "0"}} >
            <tbody>
            <tr>
              <td style={{textAlign: "left"}} >
                <Card.Subtitle className="text-muted">
                  <font style={{fontSize: "small"}}>
                  <EnsResolver lookup={post.returnValues.poster}/> ({getAbbreviatedHash(post.returnValues.poster, 4, 4)})
                  </font>
                </Card.Subtitle>
              </td>
              <td style={{textAlign: "right"}} >
                {(replyCards.length > 0) ? (
                <Button variant='secondary' size='sm'>
                  <font style={{fontSize: "smaller"}}>
                  {replyCards.length} Repl{(replyCards.length === 1)?"y":"ies"}
                  </font>
                <Badge bg='secondary' pill>
                ][ Details
                </Badge>
              </Button>
              ) : (
                <Button variant='secondary' size='sm'>
                  <Badge bg='secondary' pill>
                  ][ Details
                  </Badge>
                </Button>
              )}
              </td>
            </tr>
            </tbody>
          </table>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            {this.getPostDetails()}
          </Accordion.Collapse>
        </Card>
      </Accordion>
    <p></p>
    {(showTips) ? (
    <div>
      <ul className="replies-no-bullets">
        <CardColumns>
          {tipCards}
        </CardColumns>
      </ul>
    </div>
    ) : ("")}
    {(showReplies) ? (
    <div>
      <ul className="replies-no-bullets">
        <CardColumns>
          {replyCards}
        </CardColumns>
      </ul>
    </div>
    ) : ("")}
    </div>
  ): (
    ""
  )
  );
  }
}

export default PostCard;
