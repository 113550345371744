import React, { Component } from 'react';
import { Button, Tab, Tabs } from "react-bootstrap";

import Modal from 'react-bootstrap/Modal';

import MDEditor from '@uiw/react-md-editor';

class AboutModal extends Component {

  render() {

    const {isAboutModalOpen, platform, closeAboutModal, contract} = this.props;

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isAboutModalOpen} onHide={closeAboutModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'VT323, monospace'}}>About {platform.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>


              <Tabs defaultActiveKey="about_immutables" id="uncontrolled-tab-example">
                <Tab eventKey="about_immutables" title="Home">
                <p></p><MDEditor.Markdown source={platform.about_immutables} />
                </Tab>
                <Tab eventKey="about_pages" title="Pages">
                <p></p><MDEditor.Markdown source={platform.about_pages}/>
                </Tab>
                <Tab eventKey="about_posts" title="Posts">
                <p></p><MDEditor.Markdown source={platform.about_posts}/>
                </Tab>
                <Tab eventKey="about_tags" title="Tags">
                <p></p><MDEditor.Markdown source={platform.about_tags}/>
                </Tab>
                <Tab eventKey="about_on_chain" title="On Chain">
                <p></p><MDEditor.Markdown source={platform.about_on_chain}/>
                </Tab>
                <Tab eventKey="about_metadata" title="Metadata">
                <p></p><MDEditor.Markdown source={platform.about_metadata}/>
                </Tab>
                <Tab eventKey="about_contact" title="Contact">
                <p></p><MDEditor.Markdown source={platform.about_contact}/>
                </Tab>
              </Tabs>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} href={platform.opensea_collection_link} target="_new">
                Immutables on OpenSea
                </Button>
                {(contract) ? (
                  <Button variant="outline-secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} href={`${platform.etherscan_link}${contract.options.address}`} target="_new">
                  Contract on Etherscan
                  </Button>
                ):("")}
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} href="/#/Immutables" target="_new">
                Immutables Immutables Page
                </Button>
                <Button variant="secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={closeAboutModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default AboutModal;
