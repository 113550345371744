import React, { Component } from 'react';
import { Modal, Container, Row, Form, InputGroup, Button, Spinner, Table, Tabs, Tab} from "react-bootstrap";
import MDEditor from '@uiw/react-md-editor';
import EnsResolver from "ens-resolver";

class CreateTipModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pageInput: this.props.pageInput,
      composeFormTagContentInput: this.props.composeFormTagContentInput,
      tipFormPostAuthorInput: this.props.tipFormPostAuthorInput,
      tipFormValueInput: "0.005",
    };
  }

  setTipFormValueInput(newValue) {
    this.setState({tipFormValueInput: newValue })
  }

  updateAndNotify = () => {
    this.setState({
      pageInput: this.props.pageInput,
      composeFormTagContentInput: this.props.composeFormTagContentInput,
      tipFormPostAuthorInput: this.props.tipFormPostAuthorInput,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.pageInput !== this.props.pageInput ||
       prevState.composeFormTagContentInput !== this.props.composeFormTagContentInput ||
       prevState.tipFormPostAuthorInput !== this.props.tipFormPostAuthorInput) {
       this.updateAndNotify();
    }
  }

  render() {

    const {
      account, platform,
      isCreateTipModalOpen, currentlyPosting,
      anyonePayPagePostPosterValue,
      posterTipPercentage, contractTipPercentage,
      openAboutModal, closeCreateTipModal,
      pageOwner,
      curatorPercent,
      beneficiary,beneficiaryPercent } = this.props;
      // TODO: This does not work here.  The values are not passed to the form.
      // It works initially if these are moved up to the = this.props line.
      // However, then they cannot be edited (which defeats purpose of form ! :)
      const { pageInput, composeFormTagContentInput, tipFormPostAuthorInput, tipFormValueInput } = this.state;

    console.log("createTip - render - pageInput: ", pageInput);
    console.log("createTip - render - composeFormTagContentInput: ", composeFormTagContentInput);

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isCreateTipModalOpen} onHide={closeCreateTipModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'VT323, monospace'}}>Tip as <b><EnsResolver lookup={ account }/></b> {(account) ? `(${account.slice(0,7)}...${account.slice(account.length-8, account.length)})` : ("")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <Tabs defaultActiveKey="tip_post" id="uncontrolled-tab-example">
                <Tab eventKey="tip_post" title="Tip Post">
                <p></p><MDEditor.Markdown source={platform.compose_tip_intro} />
                <p></p>
                <Container>
                  <Row className="justify-content-md-center">
                  {/*}<div className="col-lg-12 d-flex text-center">
                    <div className="content mr-auto ml-auto">*/}

                      <form onSubmit={(event) => {
                        event.preventDefault()
                        const page = pageInput
                        const postHash = composeFormTagContentInput
                        const tip = tipFormValueInput
                        const postAuthor = tipFormPostAuthorInput
                        anyonePayPagePostPosterValue(page, postHash, postAuthor, tip)

                      }}>

                      { (currentlyPosting) ? (
                        <>
                        <div align="center">
                        <p><b>Please wait while the tip is added to the Blockchain ...</b></p>
                          <Spinner animation="grow" />
                          </div>
                        </>
                      ) : (
                        <>

                      <Form.Group controlId="newTip">

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>Page</InputGroup.Text>
                            <Form.Control required type="text" defaultValue={pageInput} readOnly/>
                            <Form.Text className="text-muted">
                            </Form.Text>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>Post Hash</InputGroup.Text>
                            <Form.Control type="text" defaultValue={composeFormTagContentInput} readOnly/>
                            <Form.Text className="text-muted">
                            </Form.Text>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>Post Author Address</InputGroup.Text>
                            <Form.Control type="text" defaultValue={tipFormPostAuthorInput} readOnly/>
                            <Form.Text className="text-muted">
                            </Form.Text>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>Amount (Ξ)</InputGroup.Text>
                            <Form.Control type="text" defaultValue={tipFormValueInput}
                                //onChange={e => this.setState({ composeFormTagContentInput: e.target.value })}
                                onChange={e => this.setTipFormValueInput(e.target.value) }
                                />
                            <Form.Text className="text-muted">
                            </Form.Text>
                          </InputGroup>
                        </Form.Group>

                      </Form.Group>
                      <Table>
                      <Row>
                          <p></p>
                          <Row>
                          <div align="center">
                          <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="I agree to the Tipping Terms and Conditions" required/>
                          </Form.Group>
                          </div>
                          </Row>
                          <input
                          id="anyonePayPagePostPosterValueSubmit"
                          type="submit"
                          className="btn btn-block btn-success"
                          style={{fontFamily: 'VT323, monospace'}}
                          value={`Add Tip and Submit Post (Amount (Ξ) + gas)`}
                        />
                        <>
                        <p></p>
                        <div align="center" style={{fontSize: "8pt"}}>
                        <div>{100 - posterTipPercentage/100 - contractTipPercentage/100}% goes to the address of the owner of the Page <EnsResolver lookup={pageOwner}/> ({pageOwner}).</div>
                        <div>{posterTipPercentage/100}% goes to the address of the author of the Post <EnsResolver lookup={tipFormPostAuthorInput}/> ({tipFormPostAuthorInput}).</div>
                        <div>{contractTipPercentage/100}% is retained by this contract.</div>
                        {(curatorPercent>0) ? (
                          <div>Of the amount retained, we pay {curatorPercent/100}% of the collected fees to {platform.cause} <EnsResolver lookup={beneficiary}/> ({beneficiary}).</div>
                          ) : ("")}
                        {(beneficiaryPercent>0) ? (
                          <div>Of the amount retained, we donate {beneficiaryPercent/100}% of the collected fees to {platform.cause} <EnsResolver lookup={beneficiary}/> ({beneficiary}).</div>
                          ) : ("")}
                        </div>
                        </>
                        </Row>
                        </Table>
                        </>
                        )}
                      </form>
                      </Row>
                      </Container>
                    {/*}</div>
                  </div>*/}
                </Tab>
              </Tabs>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="warning btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={openAboutModal}>
                  Tipping Terms and Conditions
                </Button>
                <Button variant="info btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={openAboutModal}>
                  Help / WTF Is This Website?
                </Button>
                <Button variant="secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={closeCreateTipModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default CreateTipModal;
