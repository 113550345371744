import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import MDEditor from '@uiw/react-md-editor';

class MetamaskModal extends Component {

  render() {

    const { isMetamaskModalOpen, platform, closeMetamaskModal } = this.props;

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isMetamaskModalOpen} onHide={closeMetamaskModal}>
              <Modal.Header>
                <Modal.Title style={{fontFamily: 'VT323, monospace'}}>Get MetaMask</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p></p><MDEditor.Markdown source={platform.metamask_modal} />
              </Modal.Body>
              <Modal.Footer>
              <Button variant="secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} href="https://metamask.io/" target="_new">
                Get MetaMask
              </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default MetamaskModal;