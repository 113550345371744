import React, { Component } from 'react';
import { Row, Form, InputGroup, Button, Spinner, Modal, Table, Tabs, Tab} from "react-bootstrap";
import MDEditor from '@uiw/react-md-editor';
import EnsResolver from "ens-resolver";

class AdminModal extends Component {

  constructor(props) {
    super(props);

    this.state ={
      newTeammember: "",
      newUser: "",
      newCurator: this.props.curator,
      newCuratorPercent: this.props.curatorPercent,
      newBeneficiary: this.props.beneficiary,
      newBeneficiaryPercent: this.props.beneficiaryPercent,
      newRoyaltyAddress: this.props.pageRoyaltyAddress,
      newRoyaltyPercent: this.props.pageRoyaltyPercent,
    };

    this.clickTeammember = this.clickTeammember.bind(this);
    this.onTeammemberChange = this.onTeammemberChange.bind(this);

    this.onBeneficiaryChange = this.onBeneficiaryChange.bind(this);
    this.onBeneficiaryPercentChange = this.onBeneficiaryPercentChange.bind(this);

    this.onCuratorChange = this.onCuratorChange.bind(this);
    this.onCuratorPercentChange = this.onCuratorPercentChange.bind(this);

    this.onUserChange = this.onUserChange.bind(this);
    this.clickUser = this.clickUser.bind(this);

    this.onPageRoyaltyAddressChange = this.onPageRoyaltyAddressChange.bind(this);
    this.onPageRoyaltyPercentChange = this.onPageRoyaltyPercentChange.bind(this);
  }

  clickUser(address) {
    document.getElementById("removeUserInput").value = address
    this.setState({newUser: address})
  }

  onUserChange(e) {
    this.setState({newUser: e.target.value })
  }


  clickTeammember(address) {
    document.getElementById("removeTeammemberInput").value = address
    this.setState({newTeammember: address})
  }

  onTeammemberChange(e) {
    this.setState({newTeammember: e.target.value })
  }

  onCuratorChange(e) {
    this.setState({newCurator: e.target.value })
  }

  onCuratorPercentChange(e) {
    this.setState({newCuratorPercent: e.target.value });
  }

  onBeneficiaryChange(e) {
    this.setState({newBeneficiary: e.target.value })
  }

  onBeneficiaryPercentChange(e) {
    this.setState({newBeneficiaryPercent: e.target.value });
  }

  onPageRoyaltyAddressChange(e) {
    this.setState({newRoyaltyAddress: e.target.value })
  }

  onPageRoyaltyPercentChange(e) {
    this.setState({newRoyaltyPercent: e.target.value });
  }

  render() {

    const { contract,
            isAdminModalOpen, closeAdminModal,
            adminLoading, platform,

            pageTokenId,

            postingFee, adminUpdatePostingFee,
            pageFee,adminUpdatePageFee,

            contractTipPercentage, contractOwnerUpdateContractTipPercentage,
            posterTipPercentage, contractOwnerUpdatePosterTipPercentage,

            pageRoyaltyAddress,

            userScreeningEnabled, teamToggleUserScreeningEnabled,
            allowedUsers, teamAddAllowedUser, teamRemoveAllowedUser, getAllowedUsers,

            curator, curatorPercent, contractOwnerUpdateCuratorAndPercent,
            beneficiary, beneficiaryPercent, contractOwnerUpdateBeneficiaryAndPercent,
            teammembers,
            contractOwnerAddTeammember, contractOwnerRemoveTeammember, getTeammembers,

            userIsContractOwner,
            userIsTeammember,
            userIsRoyaltyRecipient,
            userIsPayee,
            userIsPageOwner,

            pageRoyaltyContractBalance,

            useMetadataServer, adminSetUseMetadataServer,
            immutablesWEB, adminUpdateWebsiteUrl,
            immutablesURI, adminUpdateMetadataServerUrl,

            contractBalance, withdraw } = this.props;

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isAdminModalOpen} onHide={closeAdminModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'VT323, monospace'}}>Admin</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <Tabs id="uncontrolled-tab-example">
              {(userIsContractOwner) ? (
                <Tab eventKey="admin_metadata_settings" title="Metadata Settings">
                    <p></p>
                    <MDEditor.Markdown source={platform.adminServerConfigText} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>Web Server Base URL</InputGroup.Text>
                        <Form.Control required defaultValue={immutablesWEB}
                          onBlur={e => adminUpdateWebsiteUrl(e.target.value)}
                          />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>Metadata Server Base URL</InputGroup.Text>
                        <Form.Control required defaultValue={immutablesURI}
                          onBlur={e => adminUpdateMetadataServerUrl(e.target.value)}
                          />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="mb-2">
                        <Form.Check type="checkbox" checked={useMetadataServer} label="Use Metadata Server instead of Smart Contract JSON/SVG"
                        onChange={e => adminSetUseMetadataServer(e.target.checked)}
                        />
                      </InputGroup>
                    </Form.Group>
                    </>
                      )}
                </Tab>
              ) : ("")}

              {(userIsContractOwner) ? (
                <Tab eventKey="admin_user_fees" title="User Fees">
                    <p></p>
                    <MDEditor.Markdown source={platform.adminUserFeeText} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>Posting Fee (Ξ)</InputGroup.Text>
                        <Form.Control required defaultValue={postingFee}
                          onBlur={e => adminUpdatePostingFee(e.target.value)}/>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>Page Creation Fee (Ξ)</InputGroup.Text>
                        <Form.Control required defaultValue={pageFee}
                          onBlur={e => adminUpdatePageFee(e.target.value)}/>
                      </InputGroup>
                    </Form.Group>

                    <p></p>
                    <MDEditor.Markdown source={platform.adminUserValueFeeText} />
                    <p></p>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>Contract Value Percentage (basis points)</InputGroup.Text>
                        <Form.Control required defaultValue={contractTipPercentage}
                          onBlur={e => contractOwnerUpdateContractTipPercentage(e.target.value)}/>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="mb-2">
                        <InputGroup.Text>Poster Value Percentage (basis points)</InputGroup.Text>
                        <Form.Control required defaultValue={posterTipPercentage}
                          onBlur={e => contractOwnerUpdatePosterTipPercentage(e.target.value)}/>
                      </InputGroup>
                    </Form.Group>
                    </>
                    )}
                </Tab>
                ) : ("")}

                {(userIsTeammember) ? (
                <Tab eventKey="admin_add_remove_allowed_poster" title="Allowed Posters">
                    <p></p>
                    <MDEditor.Markdown source={platform.adminPosterScreening} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <hr/>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <Form.Check type="checkbox" checked={userScreeningEnabled} label="Only allow Authorized Users to create Pages and Posts."
                          onChange={e => teamToggleUserScreeningEnabled()}/>
                        </InputGroup>
                      </Form.Group>
                      <hr/>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        teamAddAllowedUser(this.state.newUser)
                      }}>
                        <Table>
                        <Row>
                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="inputGroup">
                            <InputGroup.Text>Add Address</InputGroup.Text>
                            <Form.Control required
                            //onChange={e => this.setState({newBeneficiary: e.target.value })}
                            onChange={this.onUserChange}
                            />
                          </InputGroup>
                        </Form.Group>
                        </Row>
                        <Row>
                        <input
                          id="submit"
                          type="submit"
                          className="btn btn-block btn-success"
                          style={{fontFamily: 'VT323, monospace'}}
                          value="Add Allowed Poster"
                        />
                        </Row>
                        </Table>
                      </Form>

                      <hr/>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        teamRemoveAllowedUser(this.state.newUser)
                      }}>
                        <Table>
                        <Row>
                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="inputGroup">
                            <InputGroup.Text>Remove Address</InputGroup.Text>
                            <Form.Control required
                            id="removeUserInput"
                            //onChange={e => this.setState({newBeneficiary: e.target.value })}
                            onChange={this.onUserChange}
                            />
                          </InputGroup>
                        </Form.Group>
                        </Row>
                        <Row>
                        <input
                          id="submit"
                          type="submit"
                          className="btn btn-block btn-danger"
                          style={{fontFamily: 'VT323, monospace'}}
                          value="Remove Allowed Poster"
                        />
                        </Row>
                        </Table>
                      </Form>

                      <hr/>
                      { (adminLoading) ? (
                          <>
                            <p>Please wait while the Allowed Users are Loaded ...</p>
                            <div align="center"><Spinner animation="grow" /></div>
                          </>
                        ) : (
                          <>
                            {(allowedUsers) ? (
                              Object.keys(allowedUsers).map((address, i) => (
                                (allowedUsers[address] === true) ? (
                                  <Button variant="outline-secondary btn-sm" onClick={() => this.clickUser(`${address}`)}>
                                  <EnsResolver lookup={address}/> ({address})
                                  </Button>
                                ):("")
                              ))
                             ): ("")
                            }
                            <p></p>
                            <div align="center">
                              <Button variant="success btn-sm"
                              style={{fontFamily: 'VT323, monospace'}}
                              onClick={getAllowedUsers}>Load Allowed Users</Button>
                            </div>
                          </>
                      )}
                    </>
                  )}
                </Tab>
              ) : ("")}

              {(userIsContractOwner) ? (
                <Tab eventKey="admin_curator_settings" title="Curator">
                    <p></p><MDEditor.Markdown source={platform.adminCuratorText} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <Form className="d-flex"
                      // onSubmit={(event) => {
                      //   event.preventDefault()
                      //   adminSetBeneficiaryAndPercent(this.state.newBeneficiary, this.state.newBeneficiaryPercent)}}
                      onSubmit={e => contractOwnerUpdateCuratorAndPercent(this.state.newCurator, this.state.newCuratorPercent)}
                      >
                      <Table>
                      <Row>
                      <Form.Group as={Row} className="mb-3 align-items-center">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Curator Address</InputGroup.Text>
                          <Form.Control required defaultValue={curator}
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onChange={this.onCuratorChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Curator Percent (basis points)</InputGroup.Text>
                          <Form.Control required defaultValue={curatorPercent}
                           //onChange={e => this.setState({newBeneficiaryPercent: e.target.value })}
                           onchange={this.onCuratorPercentChange}
                           />
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-success"
                        style={{fontFamily: 'VT323, monospace'}}
                        value="Update Curator Information"
                      />
                      </Row>
                      </Table>
                      </Form>
                      </>
                    )}
                </Tab>
              ) : ("")}

              {(userIsContractOwner) ? (
                <Tab eventKey="admin_add_remove_teammember" title="Teammembers">
                    <p></p>{platform.adminTeammemberText}
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>

                      <hr/>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        contractOwnerAddTeammember(this.state.newTeammember)
                      }}>
                      <Table>
                      <Row>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Add Address</InputGroup.Text>
                          <Form.Control required
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onChange={this.onTeammemberChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-success"
                        style={{fontFamily: 'VT323, monospace'}}
                        value="Add Teammember"
                      />
                      </Row>
                      </Table>
                      </Form>

                      <hr/>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        contractOwnerRemoveTeammember(this.state.newTeammember)
                      }}>
                      <Table>
                      <Row>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Remove Address</InputGroup.Text>
                          <Form.Control required
                          id="removeTeammemberInput"
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onChange={this.onTeammemberChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-danger"
                        style={{fontFamily: 'VT323, monospace'}}
                        value="Remove Teammember"
                      />
                      </Row>
                      </Table>
                      </Form>

                      <hr/>

                      { (adminLoading) ? (
                        <>
                        <p>Please wait while the Teammembers are Loaded ...</p>
                        <div align="center"><Spinner animation="grow" /></div>
                        </>
                        ) : (
                        <>
                          {(teammembers) ? (
                            Object.keys(teammembers).map((address, i) => (
                              (teammembers[address] === true) ? (
                                <Button variant="outline-secondary btn-sm" onClick={() => this.clickTeammember(`${address}`)}>
                                <EnsResolver lookup={address}/> ({address})
                                </Button>
                              ):("")
                            ))
                           ): ("")
                          }

                          <p></p>
                          <div align="center">
                            <Button variant="success btn-sm"
                            style={{fontFamily: 'VT323, monospace'}}
                            onClick={getTeammembers}>Load Teammembers</Button>
                          </div>
                        </>
                      )}
                      </>
                    )}
                </Tab>
              ) : ("")}

              {(userIsContractOwner) ? (

                <Tab eventKey="admin_beneficiary_settings" title="Beneficiary">
                    <p></p><MDEditor.Markdown source={platform.adminBeneficaryText} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <Form className="d-flex"
                      // onSubmit={(event) => {
                      //   event.preventDefault()
                      //   adminSetBeneficiaryAndPercent(this.state.newBeneficiary, this.state.newBeneficiaryPercent)}}
                      onSubmit={e => contractOwnerUpdateBeneficiaryAndPercent(this.state.newBeneficiary, this.state.newBeneficiaryPercent)}
                      >
                      <Table>
                      <Row>
                      <Form.Group as={Row} className="mb-3 align-items-center">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Beneficiary Address</InputGroup.Text>
                          <Form.Control required defaultValue={beneficiary}
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onChange={this.onBeneficiaryChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Beneficiary Percent (basis points)</InputGroup.Text>
                          <Form.Control required defaultValue={beneficiaryPercent}
                           //onChange={e => this.setState({newBeneficiaryPercent: e.target.value })}
                           onChange={this.onBeneficiaryPercentChange}
                           />
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-success"
                        style={{fontFamily: 'VT323, monospace'}}
                        value="Update Beneficiary Information"
                      />
                      </Row>
                      </Table>
                      </Form>
                      </>
                    )}
                </Tab>
              ) : ("")}

              {(userIsPayee) ? (
                <Tab eventKey="admin_withdraw_from_contract" title="Withdraw Funds">
                <p></p><MDEditor.Markdown source={platform.adminWithdrawText} />
                  <p></p>
                  This contract currently has a balance of {contractBalance}Ξ.
                  <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <Form className="d-flex"
                      // onSubmit={(event) => {
                      //   event.preventDefault()
                      //   this.withdraw()
                      //   // function withdraw() external onlyOwner() {
                      // }}
                      onSubmit={withdraw}
                      >
                      <Table>
                      <Row>
                      <Form.Group as={Row} className="mb-3 align-items-center">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Curator Address</InputGroup.Text>
                          <Form.Control readOnly defaultValue={curator}/>
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Curator Percent (basis points)</InputGroup.Text>
                          <Form.Control readOnly defaultValue={curatorPercent}/>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3 align-items-center">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Beneficiary Address</InputGroup.Text>
                          <Form.Control readOnly defaultValue={beneficiary}/>
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Beneficiary Percent (basis points)</InputGroup.Text>
                          <Form.Control readOnly defaultValue={beneficiaryPercent}/>
                        </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-success"
                        style={{fontFamily: 'VT323, monospace'}}
                        value="Withdraw Funds"
                      />
                      </Row>
                      </Table>
                      </Form>
                      </>
                    )}
                </Tab>
              ) : ("")}

              {((pageTokenId) && (userIsRoyaltyRecipient || userIsContractOwner || userIsPageOwner)) ? (
                <Tab eventKey="admin_royalty_recipient" title="Page Royalties">
                  <p></p>

                  <MDEditor.Markdown source={platform.adminRoyaltyContractAddress} />
                  <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <div align="center">
                      <b>Etherscan:</b> <a href={`${platform.etherscan_link}${pageRoyaltyAddress}`} target="_new">{pageRoyaltyAddress}</a>
                      <p></p>
                      </div>
                      <MDEditor.Markdown source={platform.adminRoyaltyContractBalance} />
                      <p></p>
                      <div align="center">
                      {pageRoyaltyContractBalance} Ξ.
                      <p></p>
                      </div>
                      <MDEditor.Markdown source={platform.adminRoyaltyContractAdministerOnEtherscan} />
                      </>
                    )}
                </Tab>
              ) : ("")}


              </Tabs>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} href={platform.opensea_collection_link} target="_new">Our Collection on OpenSea</Button>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} href={`${platform.etherscan_link}${contract.options.address}`} target="_new">Our Contract on Etherscan</Button>
                <Button variant="secondary btn-sm" style={{fontFamily: 'VT323, monospace'}} onClick={closeAdminModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default AdminModal;
